import TableSearch from 'app/components/TableSearch/TableSearch';
import React, { FC, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import PaymentNoteTable from './components/PaymentNoteTable/PaymentNoteTable';
import { IPaymentNote } from 'app/types';
import { useSearchParams } from 'react-router-dom';
import { ExcelIcon } from 'app/icons';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';

interface PaymentNoteListProps {}

const PaymentNoteList: FC<PaymentNoteListProps> = (props) => {
    // const paymentsQuery = useQuery<IPaymentNote[]>(['payment', 'notes', params], async () => {
    //     const { data } = await http.get(
    //         `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/payment-notes${params}`
    //     );

    //     setPageCount(data.pageCount);
    //     return data.data;
    // });

    // useEffect(() => {
    //     setParams(() => {
    //         let newUrl = `?page=${currentPage}`;
    //         if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;
    //         if (filter.status !== '') newUrl += `&status=${filter.status}`;
    //         if (filter.type !== '') newUrl += `&type=${filter.type}`;

    //         return newUrl;
    //     });
    // }, [currentPage, filter]);

    // const handlePageChange = (val: any) => {
    //     setCurrentPage(val.selected + 1);
    // };

    // const debouncedSearch = React.useRef(
    //     debounce((q) => {
    //         setFilter((prev) => ({ ...prev, search: q }));
    //         setCurrentPage(() => 1);
    //     }, 300)
    // ).current;

    // const handleSearch = (q: string) => {
    //     debouncedSearch(q);
    // };

    // const handleStatusFilterChange = (status: string) => {
    //     setFilter((prev) => ({ ...prev, status }));
    //     setCurrentPage(() => 1);
    // };

    // const handleTypeFilterChange = (type: string) => {
    //     setFilter((prev) => ({ ...prev, type }));
    //     setCurrentPage(() => 1);
    // };

    // React.useEffect(() => {
    //     return () => {
    //         debouncedSearch.cancel();
    //     };
    // }, [debouncedSearch]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);
    let fromDate = searchParams.get('fromDate') || '';
    let toDate = searchParams.get('toDate') || '';
    let page = searchParams.get('page') || 1;
    let status = searchParams.get('status') || '';
    let type = searchParams.get('type') || '';
    let search = searchParams.get('query') || '';

    const paymentsQuery = useQuery<IPaymentNote[]>(
        ['payment', 'notes', page, fromDate, toDate, status, type, search],
        async () => {
            let params = '?&';
            page ? (params += `page=${page}`) : (params += '');
            fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
            toDate ? (params += `&toDate=${toDate}`) : (params += '');
            status ? (params += `&status=${status}`) : (params += '');
            type ? (params += `&type=${type}`) : (params += '');
            search ? (params += `&searchParam=${search}`) : (params += '');

            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/payment-notes${params}`
            );
            setPageCount(data.pageCount);
            return data.data;
        }
    );

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('query', q);
        } else {
            searchParams.delete('query');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    const handleTypeFilterChange = (type: string) => {
        if (type === '') {
            searchParams.delete('type');
        } else {
            searchParams.set('type', type);
        }
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const handleRejectPaymentNoteStatusChange = (status: string) => {
        if (status === '') {
            searchParams.delete('status');
        } else {
            searchParams.set('status', status);
        }
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const paymentGatewayTransactionListDownloadMutation = useMutation(async () => {
        let params = '';
        fromDate ? (params += `fromDate=${fromDate}`) : (params += '');
        toDate ? (params += `&toDate=${toDate}`) : (params += '');
        status ? (params += `&filter=${status}`) : (params += '');
        type ? (params += `&type=${type}`) : (params += '');

        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/payment-notes/excel?${params}`,
            {
                responseType: 'blob',
            }
        );
        return data;
    });

    const handleDownloadExcel = async () => {
        const res = await paymentGatewayTransactionListDownloadMutation.mutateAsync();
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Payment-Note-Report.xlsx');

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
    };

    return (
        <>
            <h5 className="font-bold">Payment Notes </h5>

            <div className="card  mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    <TableSearch placeholder="Search for a payment note by id, sub agency" onSearch={handleSearch} />

                    <div className="flex justify-end gap-4">
                        <div className="flex items-center gap-3">
                            <label className="text-xs" htmlFor="filter">
                                Status
                            </label>
                            <select
                                id="filter"
                                className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                                onChange={(e: any) => handleRejectPaymentNoteStatusChange(e.target.value)}
                                value={status}
                            >
                                <option value="">All</option>
                                <option value="APPROVED">Approved</option>
                                <option value="REJECTED">Rejected</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>
                        <div className="flex items-center gap-3">
                            <label className="text-xs" htmlFor="filter">
                                Type
                            </label>
                            <select
                                id="filter"
                                className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                                onChange={(e: any) => handleTypeFilterChange(e.target.value)}
                                value={type}
                            >
                                <option value="">All</option>
                                <option value="DEBIT">Debit</option>
                                <option value="CREDIT">Credit</option>
                            </select>
                        </div>
                        <div className="flex gap-4">
                            <TableDateFilter onChange={handleDateFilter} fromDate={fromDate} toDate={toDate} />
                            <button
                                className="cursor-pointer flex items-center justify-center rounded-full"
                                onClick={handleDownloadExcel}
                                disabled={paymentGatewayTransactionListDownloadMutation.isLoading}
                            >
                                <ExcelIcon width="24" height="24" />
                            </button>
                        </div>
                    </div>
                </div>
                <PaymentNoteTable
                    payments={paymentsQuery.data}
                    isLoading={paymentsQuery.isLoading}
                    params={['payment', 'notes', page, fromDate, toDate, status, type, search]}
                />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default PaymentNoteList;
