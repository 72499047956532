import { FC } from 'react';
import http from 'app/config/http';
import Modal from 'app/components/Modal/Modal';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import { formatDate, formatTime } from 'app/utils/date-utils';
import { useParams } from 'react-router-dom';

interface PaymentReceiptDialogProps {
    show: boolean;
    onClose: () => void;
    transactionId: string;
}

const PaymentReceiptDialog: FC<PaymentReceiptDialogProps> = (props) => {
    const { show, onClose, transactionId } = props;
    const { subAgencyId } = useParams();

    const PaymentReceiptQuery = useQuery(['PaymentReceipt', transactionId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/reference-details/${transactionId}`
        );
        return data;
    });

    const transaction = PaymentReceiptQuery.data;

    return (
        <Modal show={show} onClose={onClose} className="px-10 py-10 w-[880px] max-w-full">
            <div className="text-2xl font-bold">Payment Receipt </div>
            {PaymentReceiptQuery.isLoading ? (
                <div className=" pt-8  flex justify-center ">
                    <Spinner />
                </div>
            ) : (
                <div>
                    <div className="flex flex-col mt-7 gap-4">
                        <div className="text-xs font-normal text-gray-500">Receipt Number</div>
                        <div className="text-lg font-semibold">{transaction.id}</div>
                    </div>
                    <div className="grid grid-cols-4 gap-8 mt-7 mb-6">
                        <div className="col-span-1">
                            <div className="text-xs font-normal text-gray-500">Date & Time</div>
                            <div className="text-lg font-semibold mt-4 ">
                                {formatDate(transaction.createdAt)}{' '}
                                {formatTime(transaction.createdAt.slice(-6), 'HH:mm A')}
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-xs font-normal text-gray-500">Amount</div>
                            <div className="text-lg font-semibold mt-4">{formatCurrency(transaction.amount)}</div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-xs font-normal text-gray-500">Payment Method</div>
                            <div className="text-lg font-semibold mt-4">{transaction.method}</div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-xs font-normal text-gray-500">Bank Transaction ID</div>
                            <div className="text-lg font-semibold mt-4">{transaction.details.transactionId}</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-8 mt-7 mb-6">
                        <div className="col-span-full">Remarks</div>
                    </div>
                    {Array.isArray(transaction.agentRemark) && transaction.agentRemarks.length > 0 && (
                        <div className="grid grid-cols-4 gap-8 mt-7">
                            <div className="col-span-1">
                                <div className="text-xs font-normal text-gray-500">Date & Time</div>
                            </div>
                            <div className="col-span-1">
                                <div className="text-xs font-normal text-gray-500">Agent</div>
                            </div>
                            <div className="col-span-2">
                                <div className="text-xs font-normal text-gray-500">Remark</div>
                            </div>
                        </div>
                    )}
                    {Array.isArray(transaction.agentRemark) && transaction.agentRemarks.length > 0 && (
                        <div className="grid grid-cols-4 gap-8 mt-4 mb-6">
                            {transaction.agentRemarks.map((remark: any) => {
                                return (
                                    <>
                                        <div className="col-span-1">
                                            <div className="text-lg font-semibold">
                                                {formatDate(remark.createdAt)} {formatTime(remark.createdAt)}
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            <div className="text-lg font-semibold">{remark.by.name}</div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="text-lg font-semibold">{remark.comment}</div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    )}
                    {typeof transaction.agentRemark === 'string' && transaction.agentRemark}

                    {((Array.isArray(transaction.agentRemark) && transaction.agentRemarks.length === 0) ||
                        transaction.agentRemarks === '') && (
                        <div className="grid grid-cols-1">
                            <div className="col-span-1">
                                <div className="flex">
                                    <div className="text-lg font-normal">No remarks added</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default PaymentReceiptDialog;
