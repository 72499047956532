import { getPassengerName } from './../pages/Booking/BookingList/components/BookingTable/BookingTable';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import moment from 'moment';
import { assertUnreachable } from './common-utils';
import { CabinClassEnum } from 'app/enums/cabin-class.enum';
import { RefundableEnum } from 'app/enums/refundable.enum';

interface IBarcodeGeneratorInput {
    pnr: string;
    fromAirport: string;
    toAirport: string;
    airlineCode: string;
    flightNum: string;
    DepartureDate: string;
    firstName: string;
    lastName: string;
}

const convertDateToJulian = (flightDate: string) => {
    const day = moment(flightDate, 'YYYYMMDD').dayOfYear();
    if (day < 10) {
        return '00'.concat(day.toString());
    } else if (day < 100) {
        return '0'.concat(day.toString());
    } else {
        return day.toString();
    }
};

const fixFlighNumber = (flightNum: string) => {
    const flightNumLength = flightNum.length;
    if (flightNumLength >= 4) return flightNum;
    const leftPadding = 4 - flightNumLength;
    return Array(leftPadding).fill('0').join('') + flightNum;
};

export const barCodeInputStringGenerator = (data: IBarcodeGeneratorInput): string => {
    const { pnr, fromAirport, toAirport, airlineCode, DepartureDate, flightNum, firstName, lastName } = data;

    const formatCode = 'M'; //  default value 'M'
    const numberofLegsEncoded = '1'; //  default value  '1'
    const passengerName = generatePassengerName(firstName, lastName);
    const electronicTicketIndicator = ' '; // default value  blank space (' ')
    const operatingCarrierPnrCode = addPaddingatRight(pnr, 7); // PNR and length of 7
    const fromCityAirportCode = fromAirport;
    const toCityAirportCode = toAirport;
    const operatingCarrierDesignator = addPaddingatRight(airlineCode, 3);
    const flightNumber = addPaddingatRight(fixFlighNumber(flightNum), 5);
    const dateOfFlight = addPaddingatRight(convertDateToJulian(DepartureDate), 3); //
    const compartmentCode = 'Y'; // default value 'Y' lenngth 1
    const SeatNumber = '0000'; // length 4 default '0000'
    const CheckInSequenceNumber = '00000'; // lenght 5 default '00000'
    const passengerStatus = '0'; // length 1 defaul '0'
    const fieldSizeOfVariable = '00'; // length 2 default '00'

    return [
        formatCode,
        numberofLegsEncoded,
        passengerName,
        electronicTicketIndicator,
        operatingCarrierPnrCode,
        fromCityAirportCode,
        toCityAirportCode,
        operatingCarrierDesignator,
        flightNumber,
        dateOfFlight,
        compartmentCode,
        SeatNumber,
        CheckInSequenceNumber,
        passengerStatus,
        fieldSizeOfVariable,
    ]
        .join('')
        .toUpperCase();
};

const addPaddingatRight = (input: string, totalLengthWithPadding: number) => {
    let output = input.concat(' '.repeat(totalLengthWithPadding));
    return output.substring(0, totalLengthWithPadding);
};

/**
 * Returns string of length 20.
 * format <LastName>/<FirstName><MiddleName> max length 20 characters,
 * in case less than 20 character adding paddinng on right with blank space
 */
const generatePassengerName = (firstName: string, lastName: string, middleName: string = '') => {
    let passengerName = `${lastName}/${firstName}${middleName}`;

    return addPaddingatRight(passengerName, 20);
};

export const getPassengerTypeKey = (passengerType: PassengerTypeEnum): 'children' | 'adults' | 'infants' => {
    switch (passengerType) {
        case PassengerTypeEnum.ADULT:
            return 'adults';
        case PassengerTypeEnum.CHILD:
            return 'children';
        case PassengerTypeEnum.INFANT:
            return 'infants';
        default:
            return assertUnreachable(passengerType);
    }
};

export const getPassengerTypeLabel = (passengerType: PassengerTypeEnum): string => {
    switch (passengerType) {
        case PassengerTypeEnum.ADULT:
            return 'Adult';
        case PassengerTypeEnum.CHILD:
            return 'Child';
        case PassengerTypeEnum.INFANT:
            return 'Infant';
        default:
            return assertUnreachable(passengerType);
    }
};

export const getCabinClassLabel = (cabinClass: CabinClassEnum): string => {
    switch (cabinClass) {
        case CabinClassEnum.BUSINESS:
            return 'Business';
        case CabinClassEnum.ECONOMY:
            return 'Economy';
        case CabinClassEnum.FIRST:
            return 'First Class';
        case CabinClassEnum.PREMIUM_ECONOMY:
            return 'Premium Economy';
        case CabinClassEnum.PRIVATE:
            return 'Private';
        default: {
            return assertUnreachable(cabinClass);
        }
    }
};

export const getRefundTypeLabel = (refundType: RefundableEnum): string => {
    switch (refundType) {
        case RefundableEnum.NON_REFUNDABLE:
            return 'Non Refundable';
        case RefundableEnum.PARTIAL_REFUNDABLE:
            return 'Partial Refundable';
        case RefundableEnum.REFUNDABLE:
            return 'Refundable';

        default:
            return assertUnreachable(refundType);
    }
};


