import { Status } from 'app/enums/status.enum';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import { IMySeriesDetails } from '../shared/types/my-series';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';

const FareSupplierMySeries = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<IMySeriesDetails>({
        fareIdentifier: FareSupplierEnum.MY_SERIES,
    });

    if (isLoading) return <PageLoader />;

    const data: IMySeriesDetails = isError
        ? {
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              fareRules: [] as string[],
              status: Status.DISABLED,
          }
        : queryData;

    return (
        <>
            <div className="mb-5 flex justify-between ">
                <BackLink />
                <FareSupplierActivationToggle
                    checked={data.status}
                    handleToggleStatus={handleToggleStatus}
                    fareSupplierLabel={fareSupplierLabel}
                    isLoading={isUpdatingFareSupplierStatus}
                />
            </div>
            {/* <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">My Series Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>
            </div> */}
            <FareSupplierDefaultCommissionPlan fareSupplier={fareSupplier} fareSupplierId={fareSupplierUrlKey} />

            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />

            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />

            {/* <div className="card pt-5 mt-8 pb-10">
                <div className="px-6 font-bold">Fare Identifier</div>

                <div className="px-6 mt-8">
                    <AirIqFareIdentifierForm fareIdentifier={data.fareIdentifier} />
                </div>
            </div> */}

            {!isError && (
                <>
                    <FareRuleForm
                        fareRules={data.fareRules}
                        fareSupplier={fareSupplierUrlKey}
                        refetchQueryIdentifier={queryIdentifier}
                    />
                </>
            )}
        </>
    );
};

export default FareSupplierMySeries;
