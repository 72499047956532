import Login from 'app/pages/Login/Login';
import Profile from 'app/pages/Profile/Profile';
import AuthGuard from 'app/hocs/AuthGuard/AuthGuard';
import TicketPdf from 'app/pages/TicketPdf/TicketPdf';
import Dashboard from 'app/pages/Dashboard/Dashboard';
import AmendmentList from 'app/pages/Amendment/Amendment';
import Reports from 'app/pages/Accounting/Reports/Reports';
import MainLayout from 'app/layouts/MainLayout/MainLayout';
import PageNotFound from 'app/pages/PageNotFound/PageNotFound';
import PluginList from 'app/pages/Plugin/PluginList/PluginList';
import ErrorBoundary from 'app/hocs/ErrorBoundary/ErrorBoundary';
import BookingList from 'app/pages/Booking/BookingList/BookingList';
import AgencySettings from 'app/pages/AgencySettings/AgencySettings';
import PluginDetail from 'app/pages/Plugin/PluginDetail/PluginDetail';
import SalesReport from 'app/pages/Accounting/SalesReport/SalesReport';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PaymentList from 'app/pages/Accounting/PaymentList/PaymentList';
import InternalBilling from 'app/pages/InternalBilling/InternalBilling';
import SubAgencyAdd from 'app/pages/SubAgency/SubAgencyAdd/SubAgencyAdd';
import Announcements from 'app/pages/Plugin/Announcements/Announcements';
import BookingTicket from 'app/pages/Booking/BookingTicket/BookingTicket';
import BookingDetail from 'app/pages/Booking/BookingDetail/BookingDetail';
import LoginImageList from 'app/pages/Plugin/LoginImageList/LoginImageList';
import TourQueryList from 'app/pages/TourQuery/TourQueryList/TourQueryList';
import TourQueryView from 'app/pages/TourQuery/TourQueryView/TourQueryView';
import SubAgencyEdit from 'app/pages/SubAgency/SubAgencyEdit/SubAgencyEdit';
import SubAgencyList from 'app/pages/SubAgency/SubAgencyList/SubAgencyList';
import AgencyUserAdd from 'app/pages/AgencyUser/AgencyUserAdd/AgencyUserAdd';
import BookingInvoice from 'app/pages/Booking/BookingInvoice/BookingInvoice';
import StaticPageAdd from 'app/pages/StaticPage/StaticPageAdd/StaticPageAdd';
import UnsettledPayments from 'app/pages/UnsettledPayments/UnsettledPayments';
import AgencyUserList from 'app/pages/AgencyUser/AgencyUserList/AgencyUserList';
import AgencyUserEdit from 'app/pages/AgencyUser/AgencyUserEdit/AgencyUserEdit';
import StaticPageEdit from 'app/pages/StaticPage/StaticPageEdit/StaticPageEdit';
import StaticPageList from 'app/pages/StaticPage/StaticPageList/StaticPageList';
import OffersAndUpdates from 'app/pages/Plugin/OffersAndUpdates/OffersAndUpdates';
import PaymentNoteList from 'app/pages/Accounting/PaymentNoteList/PaymentNoteList';
import SubAgencyDetails from 'app/pages/SubAgency/SubAgencyDetails/SubAgencyDetails';
import SendReminderMail from 'app/pages/Accounting/SendReminderMail/SendReminderMail';

import SubAgencyUserAdd from 'app/pages/SubAgencyUser/SubAgencyUserAdd/SubAgencyUserAdd';

import SubAgencyUserEdit from 'app/pages/SubAgencyUser/SubAgencyUserEdit/SubAgencyUserEdit';
import SubAgencyUserList from 'app/pages/SubAgencyUser/SubAgencyUserList/SubAgencyUserList';
import InternalBillingInvoice from 'app/pages/InternalBillingInvoice/InternalBillingInvoice';
import SeriesInventoryAdd from 'app/pages/SeriesInventory/SeriesInventoryAdd/SeriesInventoryAdd';
import SeriesInventoryEdit from 'app/pages/SeriesInventory/SeriesInventoryEdit/SeriesInventoryEdit';

import SeriesInventoryList from 'app/pages/SeriesInventory/SeriesInventoryList/SeriesInventoryList';
import MySeriesPayablesReport from 'app/pages/Accounting/MySeriesPayablesReport/MySeriesPayablesReport';
import SubAgencyRequestList from 'app/pages/SubAgencyRequest/SubAgencyRequestList/SubAgencyRequestList';
import SubAgencyRequestView from 'app/pages/SubAgencyRequest/SubAgencyRequestView/SubAgencyRequestView';
import BankTransactionAdd from 'app/pages/Accounting/BankTransaction/BankTransactionAdd/BankTransactionAdd';
import BankTransactionList from 'app/pages/Accounting/BankTransaction/BankTransactionList/BankTransactionList';
import BankTransactionView from 'app/pages/Accounting/BankTransaction/BankTransactionView/BankTransactionView';
import SubAgencyReceivablesReport from 'app/pages/Accounting/SubAgencyReceivablesReport/SubAgencyReceivablesReport';
import SeriesInventoryBookingList from 'app/pages/SeriesInventory/SeriesInventoryBookingList/SeriesInventoryBookingList';
import SeriesInventoryReviseMarkup from 'app/pages/SeriesInventory/SeriesInventoryReviseMarkup/SeriesInventoryReviseMarkup';

import CcAvenueInfo from 'app/pages/Plugin/CcAvenue/Page';
import PaymentListGateway from 'app/pages/Accounting/PaymentListGateway/PaymentListGateway';
import Tawk from 'app/pages/Plugin/Tawk/Tawk';

import HdfcPaymentGateway from 'app/pages/Plugin/HdfcPayment/Page';

import RoadMap from 'app/modules/road-map/RoadMap';
import FeatureRequestAdd from 'app/modules/road-map/FeatureRequest/FeatureRequestAdd/FeatureRequestAdd';
import FeatureRequestList from 'app/modules/road-map/FeatureRequest/FeatureRequestList/FeatureRequestList';
import FeatureRequestView from 'app/modules/road-map/FeatureRequest/FeatureRequestView/FeatureRequestView';
import ManualBookingForm from 'app/pages/Booking/BookingList/components/ManualBooking/ManualBookingForm/ManualBookingForm';
import SubAgencyTopUp from 'app/pages/Accounting/SubAgencyTopUp/SubAgencyTopUp';

import PassengerCalender from 'app/pages/Booking/BookingList/components/PassengerCalender/PassengerCalender';

import FareSupplierRoutes from 'app/pages/FareSupplier/FareSupplierRoutes';

//----------------------------------------  Moved to folder file
// import FareSupplierTravelBoutique from 'app/pages/FareSupplier/FareSupplierTravelBoutique/FareSupplierTravelBoutique';
// import CommissionPlanListPage from 'app/modules/fare-management/pages/commission-plan-list/Page';
// import AddCommissionPlan from 'app/modules/fare-management/pages/add-commission-plan/Page';
// import CommissionPlanAssociation from 'app/modules/fare-management/pages/commission-plan-association/Page';
// import CommissionPlanEdit from 'app/modules/fare-management/pages/commission-plan-edit/Page';

// import FareSupplierMtc from 'app/pages/FareSupplier/Mtc/FareSupplierMtc';
// import FareSupplierAgencySeries from 'app/pages/FareSupplier/FareSupplierAgencySeries/FareSupplierAgencySeries';
// import FareSupplierMySeries from 'app/pages/FareSupplier/FareSupplierMySeries/FareSupplierMySeries';
// import { FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';
// import CommissionLayout from 'app/modules/fare-management/CommissionLayout/CommissionLayout';
// import FareSupplierFareBoutique from 'app/pages/FareSupplier/FareSupplierFareBoutique/FareSupplierFareBoutique';
// import CommissionPlanView from 'app/modules/fare-management/pages/commission-plan-view/Page';
// import FareSupplierKafila from 'app/pages/FareSupplier/FareSupplierKafila/FareSupplierKafila';
// import FareSupplierTripJack from 'app/pages/FareSupplier/FareSupplierTripJack/FareSupplierTripJack';
// import FareSupplierAiriq from 'app/pages/FareSupplier/FareSupplierAiriq/FareSupplierAiriq';
// import FareSupplierList from 'app/pages/FareSupplier/FareSupplierList/FareSupplierList';
/////////////////////////////////////////////////////////////

const router = createBrowserRouter([
    { path: '/login', element: <Login />, errorElement: <ErrorBoundary /> },
    {
        path: '/',
        element: <AuthGuard page={<MainLayout />} />,
        errorElement: <ErrorBoundary />,
        children: [
            { path: '', element: <Dashboard /> },
            { path: 'series-inventory', element: <SeriesInventoryList /> },
            { path: 'series-inventory/add', element: <SeriesInventoryAdd /> },
            { path: 'series-inventory/:inventoryId/edit', element: <SeriesInventoryEdit /> },
            { path: 'series-inventory/:inventoryId/revise-markup', element: <SeriesInventoryReviseMarkup /> },
            { path: 'series-inventory/:inventoryId/bookings/:type', element: <SeriesInventoryBookingList /> },
            { path: 'sub-agencies', element: <SubAgencyList /> },
            { path: 'sub-agencies/add', element: <SubAgencyAdd /> },
            { path: 'sub-agencies/:subAgencyId/details', element: <SubAgencyDetails /> },
            { path: 'sub-agencies/:subAgencyId/edit', element: <SubAgencyEdit /> },
            { path: 'sub-agencies/:subAgencyId/users', element: <SubAgencyUserList /> },
            { path: 'sub-agencies/:subAgencyId/users/add', element: <SubAgencyUserAdd /> },
            { path: 'sub-agencies/:subAgencyId/users/:subAgentId/edit', element: <SubAgencyUserEdit /> },
            { path: 'sub-agency-requests', element: <SubAgencyRequestList /> },
            { path: 'sub-agency-requests/:requestId', element: <SubAgencyRequestView /> },

            ...FareSupplierRoutes,
            // {
            //     path: 'fare-management',
            //     children: [
            //         {
            //             index: true,
            //             path: 'fare-suppliers',
            //             element: <FareSupplierList />,
            //         },
            //         {
            //             path: `fare-suppliers/${FareSupplierUrlEnum.TRIP_JACK}`,
            //             element: <FareSupplierTripJack />,
            //         },
            //         { path: `fare-suppliers/${FareSupplierUrlEnum.AIR_IQ}`, element: <FareSupplierAiriq /> },
            //         {
            //             path: `fare-suppliers/${FareSupplierUrlEnum.TRAVEL_BOUTIQUE}`,
            //             element: <FareSupplierTravelBoutique />,
            //         },
            //         { path: `fare-suppliers/${FareSupplierUrlEnum.KAFILA}`, element: <FareSupplierKafila /> },
            //         {
            //             path: `fare-suppliers/${FareSupplierUrlEnum.FARE_BOUTIQUE}`,
            //             element: <FareSupplierFareBoutique />,
            //         },
            //         { path: `fare-suppliers/${FareSupplierUrlEnum.MTC}`, element: <FareSupplierMtc /> },
            //         {
            //             path: `fare-suppliers/${FareSupplierUrlEnum.AGENCY_SERIES}`,
            //             element: <FareSupplierAgencySeries />,
            //         },
            //         { path: `fare-suppliers/${FareSupplierUrlEnum.MY_SERIES}`, element: <FareSupplierMySeries /> },

            //         {
            //             path: 'commission-plan',
            //             element: <CommissionLayout />,
            //             children: [
            //                 {
            //                     index: true,
            //                     element: <CommissionPlanListPage />,
            //                 },
            //                 {
            //                     path: 'add',
            //                     element: <AddCommissionPlan />,
            //                 },
            //                 { path: ':id', element: <CommissionPlanView /> },
            //                 { path: ':id/edit', element: <CommissionPlanEdit /> },
            //                 { path: ':id/associate', element: <CommissionPlanAssociation /> },
            //             ],
            //         },
            //     ],
            // },

            { path: 'bookings', element: <BookingList /> },
            { path: 'bookings/passenger-calender', element: <PassengerCalender /> },

            { path: 'bookings/:bookingId/:type', element: <BookingDetail /> },
            { path: 'plugins', element: <PluginList /> },

            { path: 'plugins/ccavenue-payment', element: <CcAvenueInfo /> },
            { path: 'plugins/hdfc-payment', element: <HdfcPaymentGateway /> },

            { path: 'plugins/:pluginId', element: <PluginDetail /> },
            { path: 'offers-and-updates', element: <OffersAndUpdates /> },
            { path: 'announcements', element: <Announcements /> },
            { path: 'login-images', element: <LoginImageList /> },
            { path: 'tawk-to-chat', element: <Tawk /> },

            { path: 'users', element: <AgencyUserList /> },
            { path: 'users/add', element: <AgencyUserAdd /> },
            { path: 'users/:agentId/edit', element: <AgencyUserEdit /> },
            { path: 'settings', element: <AgencySettings /> },
            { path: 'profile', element: <Profile /> },
            { path: 'account/payments', element: <PaymentList /> },
            { path: 'account/reports', element: <Reports /> },
            { path: 'account/reports/sub-agency-receivables', element: <SubAgencyReceivablesReport /> },
            { path: 'account/reports/sub-agency-receivables/reminder', element: <SendReminderMail /> },
            { path: 'account/reports/my-series-payables', element: <MySeriesPayablesReport /> },
            { path: 'tour-query', element: <TourQueryList /> },
            { path: 'tour-query/:subAgency/:id', element: <TourQueryView /> },
            { path: '/amendments', element: <AmendmentList /> },
            { path: '/unsettled-payments', element: <UnsettledPayments /> },
            { path: '/account/payments-online', element: <PaymentListGateway /> },
            { path: 'account/transactions', element: <BankTransactionList /> },
            { path: 'account/transactions/add', element: <BankTransactionAdd /> },
            { path: 'account/transactions/:id', element: <BankTransactionView /> },
            { path: 'account/payment-note-approval', element: <PaymentNoteList /> },
            { path: 'account/supplier-report', element: <SalesReport /> },
            { path: 'account/top-up', element: <SubAgencyTopUp /> },
            { path: '/static-pages', element: <StaticPageList /> },
            { path: '/static-pages/add', element: <StaticPageAdd /> },
            { path: '/static-pages/:id/edit', element: <StaticPageEdit /> },
            { path: '/my-bills', element: <InternalBilling /> },
            { path: '/my-bills/invoice/:month', element: <InternalBillingInvoice /> },
            { path: 'road-map', element: <RoadMap /> },
            {
                path: 'feature-request',
                children: [
                    {
                        index: true,
                        element: <FeatureRequestList />,
                    },
                    {
                        path: 'add',
                        element: <FeatureRequestAdd />,
                    },
                    {
                        path: ':id',
                        element: <FeatureRequestView />,
                    },
                ],
            },
            {
                path: 'manual-booking',
                children: [
                    {
                        path: 'add',
                        index: true,
                        element: <ManualBookingForm />,
                    },
                ],
            },
        ],
    },
    { path: '/print-ticket/:token', element: <TicketPdf />, errorElement: <ErrorBoundary /> },
    {
        path: '/bookings/:bookingId/ticket/:type',
        element: <AuthGuard page={<BookingTicket />} />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/bookings/:bookingId/invoice/:type',
        element: <AuthGuard page={<BookingInvoice />} />,
        errorElement: <ErrorBoundary />,
    },
    { path: '/404', element: <PageNotFound /> },
    { path: '*', element: <PageNotFound /> },
]);

const AppRouter = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
