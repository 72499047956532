import { FC } from 'react';
import moment from 'moment';
import http from 'app/config/http';
import Modal from 'app/components/Modal/Modal';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import { IManualBookingForm } from '../types/IManualBooking';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IPassengerWithTicketNumber } from 'app/types/manual-booking';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getCabinClassLabel, getRefundTypeLabel } from 'app/utils/ticket-utils';
import { SYSTEM_DATE_FORMAT, SYSTEM_TIME_FORMAT } from 'app/utils/date-utils';

const timeFormat = (time: string) => {
    return moment(time, SYSTEM_TIME_FORMAT).format('hh:mm A');
};

const dateFormat = (date: string) => {
    return moment(date, SYSTEM_DATE_FORMAT).format('DD MMM YYYY');
};

interface IManualBookingReviewModal {
    show: boolean;
    onClose: () => void;
    manualBooking: Partial<IManualBookingForm>;
    isMutating: boolean;
    handleFinalSubmission: () => void;
}

const ManualBookingReviewModal: FC<IManualBookingReviewModal> = (props) => {
    const { show, onClose, isMutating, manualBooking, handleFinalSubmission } = props;

    const adultCount = manualBooking.booking.adults.length || 0;
    const childCount = manualBooking.booking.children.length || 0;
    const infantCount = manualBooking.booking.infants.length || 0;

    const priceDetails: any = {};

    if (adultCount > 0) {
        priceDetails.adult = {
            base: +manualBooking.booking.priceDetails.adult.base,
            taxAndFees: +manualBooking.booking.priceDetails.adult.taxAndFees,
        };
    }

    if (childCount > 0) {
        priceDetails.child = {
            base: +manualBooking.booking.priceDetails.child.base,
            taxAndFees: +manualBooking.booking.priceDetails.child.taxAndFees,
        };
    }

    if (infantCount > 0) {
        priceDetails.infant = {
            base: +manualBooking.booking.priceDetails.infant.base,
            taxAndFees: +manualBooking.booking.priceDetails.infant.taxAndFees,
        };
    }

    priceDetails.managementFeesPerSeat = +manualBooking.booking.priceDetails.managementFeesPerSeat;

    const result = {
        adultCount,
        childCount,
        infantCount,
        priceDetails,
    };

    const agencyBankAccountQuery = useQuery<any>(['tax', 'details'], async () => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/manual-booking/fare-description`,
            result
        );
        return data;
    });

    if (agencyBankAccountQuery.isLoading)
        return (
            <Modal show={show} onClose={onClose}>
                <div className="w-[720px] p-6">
                    <div className="font-bold">Review your details</div>
                    <div className="h-64 flex justify-center items-center">
                        <Spinner />
                    </div>
                </div>
            </Modal>
        );

    return (
        <Modal show={show} onClose={onClose}>
            <div className="p-6">
                <div className="font-bold">Review your details</div>
                <div className="card px-6 pt-5 pb-6 mt-6">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                            <label className="text-xs mb-2 label-required" htmlFor="subAgency">
                                SUB AGENCY
                            </label>
                            <div className="input-group font-bold">{manualBooking.ticket.subAgency.name}</div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2 label-required" htmlFor="meta.fareSupplier">
                                FARE SUPPLIER
                            </label>
                            <div className="input-group font-bold">
                                <div>
                                    {manualBooking.ticket.meta.fareSupplier === 'OTHER'
                                        ? manualBooking.ticket.meta.fareSupplierName
                                        : createReadableFareSupplierLabel(
                                              manualBooking.ticket.meta.fareSupplier as FareSupplierEnum
                                          )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2 label-required" htmlFor="bookingId">
                                SUPPLIER BOOKING ID
                            </label>
                            <div className="input-group font-bold">{manualBooking.ticket.bookingId}</div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2" htmlFor="pnr">
                                PNR
                            </label>
                            <div className="input-group font-bold">{manualBooking.ticket.pnr}</div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2 label-required " htmlFor="cabinClass">
                                Cabin Class
                            </label>
                            <div className="input-group font-bold">
                                {getCabinClassLabel(manualBooking.ticket.cabinClass)}
                            </div>
                        </div>
                        {/* {values.meta.fareSupplier === 'OTHER' && (
                        <div className="col-span-3">
                            <label className="text-xs mb-2 label-required " htmlFor="meta.fareSupplierName">
                                Supplier Name
                            </label>
                            <div className="input-group font-bold">
                                <Field
                                    type="text"
                                    id="meta.fareSupplierName"
                                    name="meta.fareSupplierName"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="meta.fareSupplierName" />
                            </div>
                        </div>
                    )} */}
                        <div className="col-span-3">
                            <label className="text-xs mb-2 label-required" htmlFor="refundableType">
                                Booking Type
                            </label>
                            <div className="input-group font-bold">
                                {getRefundTypeLabel(manualBooking.ticket.refundableType)}
                            </div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2" htmlFor="classOfBooking">
                                Class Of Booking
                            </label>
                            <div className="input-group font-bold">{manualBooking.ticket?.classOfBooking || '-'}</div>
                        </div>
                        <div className="col-span-3">
                            <label className="text-xs mb-2" htmlFor="fareIdentifier">
                                Fare Identifier
                            </label>
                            <div className="input-group font-bold">{manualBooking.ticket?.fareIdentifier || '-'}</div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    {/* <label className="text-xs mb-2" htmlFor="fareIdentifier">
                        Segments
                    </label>

                    <div className="font-bold">
                        {manualBooking.ticket.segments[0].departure.airport.city} to{' '}
                        {manualBooking.ticket.segments[manualBooking.ticket.segments.length - 1].arrival.airport.city}
                    </div> */}

                    <div className="card px-6 pt-5 pb-6 mt-6">
                        <label className="text-xs mb-2" htmlFor="fareIdentifier">
                            Segments
                        </label>

                        <div className="font-bold mb-2">
                            {manualBooking.ticket.segments[0].departure.airport.city} to{' '}
                            {
                                manualBooking.ticket.segments[manualBooking.ticket.segments.length - 1].arrival.airport
                                    .city
                            }
                        </div>
                        {manualBooking.ticket.segments.map((segment) => (
                            <>
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-6">
                                        <label className="text-xs mb-2">From</label>
                                        <div className="font-bold">
                                            {segment.departure.airport.code}-{segment.departure.airport.city}{' '}
                                            {segment.departure.airport?.terminal}
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <label className="text-xs mb-2">To</label>
                                        <div className="font-bold">
                                            {segment.arrival.airport.code}-{segment.arrival.airport.city}{' '}
                                            {segment.arrival.airport?.terminal}
                                        </div>
                                    </div>
                                    <div className="col-span-3">
                                        <label className="text-xs mb-2">Depart Date</label>
                                        <div className="font-bold">{dateFormat(segment.departure.date)} </div>
                                    </div>
                                    <div className="col-span-3">
                                        <label className="text-xs mb-2">Depart Time</label>
                                        <div className="font-bold">{timeFormat(segment.departure.time)}</div>
                                    </div>
                                    <div className="col-span-3">
                                        <label className="text-xs mb-2">Arrival Date</label>
                                        <div className="font-bold">{dateFormat(segment.arrival.date)}</div>
                                    </div>
                                    <div className="col-span-3">
                                        <label className="text-xs mb-2">Arrival Time</label>
                                        <div className="font-bold">{timeFormat(segment.arrival.time)}</div>
                                    </div>
                                    <div className="col-span-6">
                                        <label className="text-xs mb-2">Flight</label>
                                        <div className="font-bold">
                                            {segment.flight.name} {segment.flight.code}
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <label className="text-xs mb-2">Flight Number</label>
                                        <div className="font-bold">{segment.flightNumber} </div>
                                    </div>
                                </div>
                                <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                            </>
                        ))}
                    </div>

                    <div className="card px-6 pt-5 pb-6 mt-6">
                        {/* Adults */}
                        <div className="mt-4">
                            {manualBooking.booking.adults.map((pax, index) => (
                                <>
                                    <PassengerDetails pax={pax} index={index} paxType="Adult" />
                                    <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                                </>
                            ))}
                            {/* {<hr className="border-t-2 border-gray-300 my-4 last:hidden" />} */}
                        </div>

                        {/* Child*/}
                        <div className=" mt-2">
                            {manualBooking.booking.children.length > 0 && (
                                <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                            )}
                            {manualBooking.booking.children.map((pax, index) => (
                                <>
                                    <PassengerDetails pax={pax} index={index} paxType="Child" />
                                    <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                                </>
                            ))}
                        </div>

                        {/* Infant */}
                        <div className="mt-2">
                            {manualBooking.booking.infants.length > 0 && (
                                <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                            )}
                            {manualBooking.booking.infants.map((pax, index) => (
                                <>
                                    <PassengerDetails pax={pax} index={index} paxType="Infant" />
                                    <hr className="border-t-2 border-gray-300 my-4 last:hidden" />
                                </>
                            ))}
                        </div>
                    </div>

                    <div className="card px-6 pt-5 pb-6 mt-6">
                        <div className="font-bold">Fare Summary</div>
                        <div className="mt-6">
                            <div className="flex justify-between">
                                <div className="text-sm">Base Fare</div>
                                <div className="text-sm">
                                    {formatCurrency(agencyBankAccountQuery.data.totalBaseFare)}
                                </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                                <div className="text-sm">Tax & Fees</div>
                                <div className="text-sm">
                                    {/* {formatCurrency(booking.priceDetail.totalTaxesAndFees + booking.markup)} */}
                                    {formatCurrency(agencyBankAccountQuery.data.totalTaxesAndFees)}
                                </div>
                            </div>
                            <div className=" mt-4 flex justify-between">
                                <div className="text-sm">Management Fare</div>
                                <div className="text-sm">
                                    {formatCurrency(agencyBankAccountQuery.data.totalMgtFees)}
                                </div>
                            </div>
                        </div>
                        <div className="text-sm font-semibold flex justify-between mt-8">
                            <div>Total Amount</div>
                            <div>{formatCurrency(agencyBankAccountQuery.data.totalFare)}</div>
                        </div>
                        {/* <div className="mt-3 text-sm font-semibold flex justify-between">
                            <div>Payment (AGY BAL)</div>
                            <div className="text-success">-{formatCurrency(agencyBankAccountQuery.data.totalFare)}</div>
                        </div> */}
                        {/* <div className="mt-8 text-sm font-semibold flex justify-between">
                            <div>Balance</div>
                            <div className="text-success">₹ 0</div>
                        </div> */}
                    </div>
                </div>

                <div className="flex items-center justify-between ">
                    <LoaderButton
                        onClick={handleFinalSubmission}
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-full mt-12 "
                        isLoading={isMutating}
                    >
                        Confirm
                    </LoaderButton>
                </div>

                {/* <div className="mt-8">
                    <div className="text-gray-900 font-bold">Baggage Information</div>

                    <div className="grid grid-cols-12 gap-6 mt-4">
                        <div className="col-span-4">
                            <label className="text-xs mb-2 label-required " htmlFor="cabin">
                                CABIN
                            </label>
                            <div className="input-group font-bold">
                                <Field type="text" id="cabin" name="baggage.cabin" className="form-control" />

                                <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">Kg</div>
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="baggage.cabin" />
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2 label-required  " htmlFor="checkIn">
                                CHECK IN
                            </label>
                            <div className="input-group font-bold">
                                <Field type="text" id="checkIn" name="baggage.checkIn" className="form-control" />

                                <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">Kg</div>
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="baggage.checkIn" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Modal>
    );
};

interface IPassengerDetails {
    pax: IPassengerWithTicketNumber;
    index: number;
    paxType: 'Adult' | 'Child' | 'Infant';
}

const PassengerDetails: FC<IPassengerDetails> = (props) => {
    const { pax, index, paxType } = props;

    return (
        <>
            <div className="grid grid-cols-12 gap-4" key={pax.firstName + pax.lastName + index}>
                <label className="text-xs mb-2 font-bold">
                    {paxType} {index + 1}
                </label>
                <div className="col-span-2">
                    <label className="text-xs mb-2">Title</label>
                    <div className="font-bold">{pax.title}</div>
                </div>
                <div className="col-span-3">
                    <label className="text-xs mb-2">First Name</label>
                    <div className="font-bold">{pax.firstName} </div>
                </div>
                <div className="col-span-3">
                    <label className="text-xs mb-2">Last Name</label>
                    <div className="font-bold">{pax.lastName}</div>
                </div>
                <div className="col-span-3">
                    <label className="text-xs mb-2">D.O.B</label>
                    <div className="font-bold">{pax.dob ? dateFormat(pax.dob) : '-'}</div>
                </div>
            </div>
            {pax.passport.number && (
                <div className="grid grid-cols-12 mt-4">
                    <label className="text-xs mb-2 font-bold"></label>

                    <div className="col-span-2">
                        <label className="text-xs mb-2">Password No.</label>
                        <div className="font-bold ">{pax.passport.number}</div>
                    </div>
                    <div className="col-span-3">
                        <label className="text-xs mb-2">Issue Date</label>
                        <div className="font-bold ">
                            {pax.passport.issueDate ? dateFormat(pax.passport.issueDate) : ''}
                        </div>
                    </div>
                    <div className="col-span-3">
                        <label className="text-xs mb-2">Expiry</label>
                        <div className="font-bold ">{pax.passport.expiry ? dateFormat(pax.passport.expiry) : ''}</div>
                    </div>
                    <div className="col-span-3">
                        <label className="text-xs mb-2">Country</label>
                        <div className="font-bold ">{pax.passport.nationality} </div>
                    </div>
                </div>
            )}
            {/* <hr className="border-t-2 border-gray-300 my-4 last:hidden" />  */}
        </>
    );
};

export default ManualBookingReviewModal;
