import * as Yup from 'yup';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const MtcCredentialFormSchema = Yup.object({
    token: Yup.string()
        .required('This field is required')
        .test('password', 'Invalid Token', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    username: Yup.string().required('This field is required'),
    password: Yup.string()
        .required('This field is required')
        .test('password', 'Invalid Password', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    baseUrl: Yup.string().required('This field is required'),
    auth_channel: Yup.string().required('This field is required'),
});

interface IMtcCredentialForm {
    auth_channel: string;
    token: string;
    username: string;
    password: string;
    baseUrl: string;
}

const MtcCredentialForm: FC<IMtcCredentialForm> = (props) => {
    const { auth_channel, baseUrl, username, password, token } = props;

    const initialValues = useMemo(() => {
        return {
            auth_channel: auth_channel || '',
            baseUrl: baseUrl || '',
            username: username || '',
            password: password || '',
            token: token || '',
            // token_id: token_id || '',
        };
    }, [auth_channel, baseUrl, username, password, token]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/mtc`, payload);

        return data;
    });

    const handleSubmit = async (values: IMtcCredentialForm) => {
        try {
            const authKeys = {
                credentials: {
                    username: values.username,
                    password: values.password,
                    token: values.token,
                    // tokenId: values.token_id,
                    auth_channel: values.auth_channel,
                },
                baseUrl: values.baseUrl,
            };

            await mutation.mutateAsync(authKeys);

            toast.success('API Key updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            console.log(ex);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={MtcCredentialFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="grid grid-cols-12 gap-4 mt-8">
                        {/* baseUrl */}
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="baseUrl">
                                Base Url
                            </label>
                            <Field
                                type="text"
                                id="baseUrl"
                                name="baseUrl"
                                className="form-control"
                                placeholder="Enter MTC base url"
                            />
                            <div className="form-error">
                                <ErrorMessage name="baseUrl" />
                            </div>
                        </div>
                        {/* username */}
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="username">
                                Username
                            </label>
                            <Field
                                type="text"
                                id="username"
                                name="username"
                                className="form-control"
                                placeholder="Enter MTC username"
                            />
                            <div className="form-error">
                                <ErrorMessage name="username" />
                            </div>
                        </div>
                        {/* password */}
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="password">
                                Password
                            </label>
                            <Field
                                type="text"
                                id="password"
                                name="password"
                                className="form-control"
                                placeholder="Enter MTC password"
                                onFocus={() => setFieldValue('password', '')}
                            />
                            <div className="form-error">
                                <ErrorMessage name="password" />
                            </div>
                        </div>
                        {/* token */}
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="token">
                                Token
                            </label>
                            <Field
                                type="text"
                                id="token"
                                name="token"
                                className="form-control"
                                placeholder="Enter your MTC token"
                            />
                            <div className="form-error">
                                <ErrorMessage name="token" />
                            </div>
                        </div>
                        {/* token_id */}
                        {/* <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="token_id">
                                Token ID
                            </label>
                            <Field
                                type="text"
                                id="token_id"
                                name="token_id"
                                className="form-control"
                                placeholder="Enter MTC token id"
                                onFocus={() => setFieldValue('password', '')}
                            />
                            <div className="form-error">
                                <ErrorMessage name="token_id" />
                            </div>
                        </div> */}

                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="auth_channel">
                                Auth Channel
                            </label>
                            <Field
                                type="text"
                                id="auth_channel"
                                name="auth_channel"
                                className="form-control"
                                placeholder="Enter MTC auth channel"
                                onFocus={() => setFieldValue('apiKey', '')}
                            />
                            <div className="form-error">
                                <ErrorMessage name="auth_channel" />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default MtcCredentialForm;
