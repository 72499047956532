import { RouteObject } from 'react-router-dom';
import AddCommissionPlan from 'app/modules/fare-management/pages/add-commission-plan/Page';
import CommissionLayout from 'app/modules/fare-management/CommissionLayout/CommissionLayout';
import CommissionPlanView from 'app/modules/fare-management/pages/commission-plan-view/Page';
import CommissionPlanEdit from 'app/modules/fare-management/pages/commission-plan-edit/Page';
import CommissionPlanListPage from 'app/modules/fare-management/pages/commission-plan-list/Page';
import CommissionPlanAssociation from 'app/modules/fare-management/pages/commission-plan-association/Page';

const CommissionPlanRoutes: Array<RouteObject> = [
    {
        path: 'commission-plan',
        element: <CommissionLayout />,
        children: [
            {
                index: true,
                element: <CommissionPlanListPage />,
            },
            {
                path: 'add',
                element: <AddCommissionPlan />,
            },
            { path: ':id', element: <CommissionPlanView /> },
            { path: ':id/edit', element: <CommissionPlanEdit /> },
            { path: ':id/associate', element: <CommissionPlanAssociation /> },
        ],
    },
];

export default CommissionPlanRoutes;
