/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup';
import { FC } from 'react';
import moment from 'moment';
import http from 'app/config/http';
import Modal from 'app/components/Modal/Modal';
import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';

interface ISubAgencyClosingBalanceReportModel {
    show: boolean;
    onClose: () => void;
}

const SubAgencyClosingBalanceReportModel: FC<ISubAgencyClosingBalanceReportModel> = (props) => {
    const { onClose, show } = props;

    const getMinDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 1); // One year in the past
        return date;
    };

    const ReportFormSchema = Yup.object().shape({
        reportDate: Yup.date()
            .nullable()
            .required('This field is required')
            .max(new Date(), 'Date cannot be in the future')
            .min(getMinDate(), 'Date must be within the past year'),
    });

    const subAgencyClosingBalanceReportDownloadMutation = useMutation(async (date: string) => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/closing-balance/excel?date=${date}`,
            {
                responseType: 'blob',
            }
        );
        return data;
    });

    const handleDownloadExcel = async (date: string) => {
        let formattedDate = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
        const res = await subAgencyClosingBalanceReportDownloadMutation.mutateAsync(formattedDate);
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Closing-Balance-Report.xlsx');

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
        onClose();
    };

    return (
        <Modal
            className="px-6 py-6 w-[480px] "
            show={show}
            onClose={onClose}
            isLoading={subAgencyClosingBalanceReportDownloadMutation.isLoading}
        >
            <div className="font-bold text-lg">Sub Agency Closing Balances</div>
            <Formik
                initialValues={{ reportDate: moment().format('YYYY-MM-DD') }}
                enableReinitialize
                validationSchema={ReportFormSchema}
                onSubmit={async (values) => {
                    handleDownloadExcel(values.reportDate);
                }}
            >
                {({ values, setFieldValue }: FormikProps<any>) => (
                    <Form>
                        {/* name , gst */}
                        <div className="grid grid-cols-12 gap-6 w-full mt-4">
                            <div className="col-span-full">
                                {/* <label className="text-xs mb-2 " htmlFor="reportDate">
                                    Sub Agency Balances on Date
                                </label> */}
                                <Field type="date" id="reportDate" name="reportDate" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="reportDate" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-full max-w-full mt-4"
                            isLoading={subAgencyClosingBalanceReportDownloadMutation.isLoading}
                        >
                            Generate Report
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
            <div className="text-xs black/5 mt-4 flex items-center">* Report generation may take a few minutes</div>
        </Modal>
    );
};

export default SubAgencyClosingBalanceReportModel;
