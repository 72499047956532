import { FC } from 'react';
import useDialog from 'app/hooks/use-dialog';
import { formatDate } from 'app/utils/date-utils';
import PaymentNotes from '../PaymentNotes/PaymentNotes';
import { formatCurrency } from 'app/utils/currency-utils';
import BookingDetailDialog from '../BookingDetailDialog/BookingDetailDialog';
import BookingInvoiceDialog from '../BookingInvoiceDialog/BookingInvoiceDialog';
import PaymentReceiptDialog from '../PaymentReceiptDialog/PaymentReceiptDialog';
import moment from 'moment';
import Tooltip from 'app/components/Tooltip/Tooltip';

interface TransactionTableProps {
    transactions: any;
}

const TransactionTable: FC<TransactionTableProps> = (props) => {
    const { transactions } = props;

    const paymentNoteDialog = useDialog<{ _id: string; type: string }>({ _id: '', type: '' });
    const bookingDialog = useDialog<{ _id: string; type: string }>({ _id: '', type: '' });
    const invoiceDialog = useDialog<{ _id: string; type: string }>({ _id: '', type: '' });

    const transactionTypeMessage: any = {
        ADD_MONEY_TO_WALLET: 'Money added to wallet',
        FLIGHT_BOOKING: 'Flight Booking',
        OPENING_BALANCE: 'Opening Balance',
        COMMISSION: 'Booking Commission',
    };

    const getTransactionTypeMessage = (type: string, category: string) => {
        if (type === 'PAYMENT_NOTE') {
            return category === 'CREDIT' ? 'Credit Note' : 'Debit Note';
        } else {
            return transactionTypeMessage[type];
        }
    };

    const getCreditAmountLabel = (type: string, category: string, amount: number) => {
        if (category === 'OPENING_BALANCE' && amount > 0) {
            return <span className="text-green-500">{formatCurrency(amount)} </span>;
        } else if (category === 'PAYMENT' || category === 'COMMISSION_PAYMENT') {
            return <span className="text-green-500">{formatCurrency(amount)} </span>;
        } else if (type === 'PAYMENT_NOTE' && category === 'CREDIT') {
            return <span className="text-green-500">{formatCurrency(amount)} </span>;
        } else {
            return '-';
        }
    };

    const getDebitAmountLabel = (type: string, category: string, amount: number) => {
        if (category === 'OPENING_BALANCE' && amount <= 0) {
            return <span className="text-red-500">{formatCurrency(amount)} </span>;
        } else if (category === 'INVOICE') {
            return <span className="text-red-500">{formatCurrency(amount)} </span>;
        } else if (type === 'PAYMENT_NOTE' && category === 'DEBIT') {
            return <span className="text-red-500">{formatCurrency(amount)} </span>;
        } else {
            return '-';
        }
    };

    const paymentDialog = useDialog<string>('');

    const handleDialogOpenner = (tranType: string, _id: string, ref: any) => {
        if (tranType === 'PAYMENT') {
            paymentDialog.show(_id);
        }

        if (tranType === 'CREDIT' || tranType === 'DEBIT') {
            if (tranType === 'CREDIT') {
                paymentNoteDialog.show({ _id, type: 'Credit' });
            }
            if (tranType === 'DEBIT') {
                paymentNoteDialog.show({ _id, type: 'Debit' });
            }
        }

        if (tranType === 'INVOICE') {
            // window.open(`/bookings/${ref}/invoice/AG`, '_blank');
            invoiceDialog.show({ _id: ref, type: 'AG' });
        }
        if (tranType === 'COMMISSION_PAYMENT') {
            // navigate(`/bookings/${ref}/AG`);
            bookingDialog.show({ _id: ref, type: 'AG' });
        }
    };

    return (
        <>
            <div className="overflow-auto">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="th-1">Date</th>
                            <th className="th-1">Transaction Type</th>
                            <th className="th-1">Reference ID</th>
                            <th className="th-1">Credit</th>
                            <th className="th-1"> Debit</th>
                            <td className="th-1 text-right ">Balance </td>
                            {/* <th className="th-1"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((row: any, i: number) => (
                            <tr key={i} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div className="font-semibold">{formatDate(row.createdAt)}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        {' '}
                                        {getTransactionTypeMessage(row.type, row.category)}
                                    </div>
                                    <div className="text-xs mt-2 ">
                                        {row.category === 'INVOICE' && (
                                            <Tooltip
                                                content={
                                                    <div className="w-56 h-32 p-4">
                                                        <div className="font-semibold">Passenger(s)</div>
                                                        <ul className="text-sm">
                                                            {row.metadata?.passengerNames.map(
                                                                (pax: string, index: number) => (
                                                                    <li>
                                                                        {' '}
                                                                        {index + 1}) {pax}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                {`${row.metadata?.segment} / ${
                                                    row.metadata?.departureDate
                                                        ? moment(row.metadata.departureDate, 'YYYYMMDD').format(
                                                              'DD MMM, YYYY'
                                                          )
                                                        : ' - '
                                                } / ${row.metadata?.pnr} / ${row.metadata?.passengerNames.length} Pax `}
                                            </Tooltip>
                                        )}
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        {row.referenceId || '-'}

                                        {row.referenceId && (
                                            <div
                                                onClick={() =>
                                                    handleDialogOpenner(row.category, row._id, row.referenceId)
                                                }
                                                className="text-blue-500  cursor-pointer"
                                            >
                                                {row.category === 'PAYMENT' ? 'View Receipt' : ''}
                                                {row.category === 'CREDIT' && row.type === 'PAYMENT_NOTE'
                                                    ? 'View  Credit Note'
                                                    : row.category === 'DEBIT' && row.type === 'PAYMENT_NOTE'
                                                    ? 'View Debit Note'
                                                    : ''}

                                                {row.category === 'INVOICE' ? 'View Invoice' : ''}
                                                {row.category === 'COMMISSION_PAYMENT' ? 'View Booking' : ''}
                                            </div>
                                        )}
                                    </div>
                                </td>

                                <td className="text-sm font-semibold py-4">
                                    {getCreditAmountLabel(row.type, row.category, row.amount)}
                                </td>
                                <td className="text-sm font-semibold py-4">
                                    {getDebitAmountLabel(row.type, row.category, row.amount)}
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-right">{formatCurrency(row.balance)}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {paymentDialog.isOpen && (
                <PaymentReceiptDialog
                    show={paymentDialog.isOpen}
                    onClose={paymentDialog.hide}
                    transactionId={paymentDialog.data}
                />
            )}
            {paymentNoteDialog.isOpen && (
                <PaymentNotes
                    show={paymentNoteDialog.isOpen}
                    onClose={paymentNoteDialog.hide}
                    type={paymentNoteDialog.data.type}
                    transactionId={paymentNoteDialog.data._id}
                />
            )}
            {bookingDialog.isOpen && (
                <BookingDetailDialog
                    show={bookingDialog.isOpen}
                    onClose={bookingDialog.hide}
                    bookingId={bookingDialog.data._id}
                    type={bookingDialog.data.type}
                />
            )}

            {invoiceDialog.isOpen && (
                <BookingInvoiceDialog
                    show={invoiceDialog.isOpen}
                    onClose={invoiceDialog.hide}
                    bookingId={invoiceDialog.data._id}
                    type={invoiceDialog.data.type}
                />
            )}
        </>
    );
};

export default TransactionTable;
