import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import AirIqFareIdentifierForm from '../shared/components/AirIqFareIdentifierForm/AirIqFareIdentifierForm';
import AiriqCredentialForm from '../shared/components/AiriqCredentialForm/AiriqCredentialForm';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { IAirIqDetails } from '../shared/types/air-iq';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import { Status } from 'app/enums/status.enum';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';

import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';

interface FareSupplierAiriqProps {}

const FareSupplierAiriq: FC<FareSupplierAiriqProps> = (props) => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<IAirIqDetails>({
        fareIdentifier: FareSupplierEnum.AIR_IQ,
    });

    if (isLoading) return <PageLoader />;

    const data: IAirIqDetails = isError
        ? {
              apiKey: '',
              username: '',
              password: '',
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              fareIdentifier: '',
              fareRules: [] as string[],
              baseUrl: '',
              status: Status.DISABLED,
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Airiq Details</div>

                    <FareSupplierActivationToggle
                        checked={data.status}
                        handleToggleStatus={handleToggleStatus}
                        fareSupplierLabel={fareSupplierLabel}
                        isLoading={isUpdatingFareSupplierStatus}
                    />
                </div>
                <AiriqCredentialForm
                    apiKey={data.apiKey}
                    username={data.username}
                    password={data.password}
                    baseUrl={data.baseUrl}
                />
            </div>
            <FareSupplierDefaultCommissionPlan fareSupplier={fareSupplier} fareSupplierId={fareSupplierUrlKey} />
            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />
            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />
            <div className="card pt-5 mt-8 pb-10">
                <div className="px-6 font-bold">Fare Identifier</div>

                <div className="px-6 mt-8">
                    <AirIqFareIdentifierForm fareIdentifier={data.fareIdentifier} />
                </div>
            </div>

            {!isError && (
                <>
                    <FareRuleForm
                        fareRules={data.fareRules}
                        fareSupplier={'air-iq'}
                        refetchQueryIdentifier={queryIdentifier}
                    />
                </>
            )}
        </>
    );
};

export default FareSupplierAiriq;
