/* eslint-disable jsx-a11y/anchor-is-valid */
import http from 'app/config/http';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import { Link } from 'react-router-dom';
import useDialog from 'app/hooks/use-dialog';
import { FC, useLayoutEffect, useRef } from 'react';
import Spinner from 'app/components/Spinner/Spinner';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { formatCurrency } from 'app/utils/currency-utils';
import InfoModal from 'app/components/InfoModal/InfoModal';
import { IGatewayPayment } from '../../PaymentListGateway';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { PaymentStatusEnum } from 'app/enums/payment-status.enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { produce } from 'immer';
import { getGatewayPaymentModeEnumLabel, getPaymentEnumLabel } from 'app/utils/payment-gateway.utils';

type IConfirmation = {
    action: () => void;
    payment: IGatewayPayment;
    type: PaymentStatusEnum.APPROVED | PaymentStatusEnum.REJECTED;
};

interface PaymentTableProps {
    payments: IGatewayPayment[];
    isLoading: boolean;
    params: any;
}

type IStatusUpdate = {
    status: PaymentStatusEnum.APPROVED | PaymentStatusEnum.REJECTED;
    payment: IGatewayPayment;
};

const PaymentTable: FC<PaymentTableProps> = (props) => {
    const tableTop = useRef();
    const queryClient = useQueryClient();
    const infoDialog = useDialog<string>();
    const confirmationDialog = useDialog<IConfirmation>();

    const { payments, params, isLoading } = props;

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const gatewayPaymentStatusUpdateMutation = useMutation(async (values: IStatusUpdate) => {
        const { data } = await http.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/${values.payment._id}/status`,
            {
                status: values.status,
            }
        );

        console.log({ data });

        return data.payment as IGatewayPayment;
    });

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'APPROVED':
                return 'green';
            case 'REJECTED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'APPROVED':
                return 'Approved';
            case 'REJECTED':
                return 'Rejected';
        }
    };

    const getMethodText = (method: string) => {
        switch (method) {
            case 'BANK_TRANSFER':
                return 'Bank Transfer';
            case 'CHEQUE_OR_CASH':
                return 'Cheque / Cash';
            case 'RAZORPAY_GATEWAY':
                return 'Online';
            case 'CCAVENUE_GATEWAY':
                return 'CCAvenue';
            case 'HDFC_GATEWAY':
                return 'HDFC Gateway';
        }
    };

    const actionHandler = (payload: IStatusUpdate) => {
        confirmationDialog.show({
            action: () =>
                handlePaymentStatusUpdate({
                    payment: payload.payment,
                    status: payload.status,
                }),
            type: payload.status,
            payment: payload.payment,
        });
    };

    const handlePaymentStatusUpdate = async (payload: IStatusUpdate) => {
        if (gatewayPaymentStatusUpdateMutation.isLoading) return;

        try {
            const payment = await gatewayPaymentStatusUpdateMutation.mutateAsync(payload);

            queryClient.setQueryData<IGatewayPayment[]>(params, (prev) => {
                const index = prev.findIndex((p) => p._id === payload.payment._id);
                return produce(prev, (draft) => {
                    draft[index] = payment;
                });
            });

            queryClient.fetchQuery(params);
            confirmationDialog.hide();
            toast.success(
                `Payment  ${payload.status === PaymentStatusEnum.APPROVED ? ' Approved ' : 'Rejected'}  Successfully.`
            );
        } catch (ex: any) {
            console.log({ ex });
            infoDialog.show(ex.data.message);
            // toast.error(ex.data.message);
        } finally {
        }
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1">Payment ID</th>
                            <th className="th-1">Sub Agency</th>
                            <th className="th-1">Transaction ID</th>
                            <th className="th-1">Gateway</th>
                            <th className="th-1">Mode</th>
                            <th className="th-1">Net Amount</th>
                            <th className="th-1">Gateway Charges</th>
                            <th className="th-1">Transaction Date</th>
                            <th className="th-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && payments.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}
                        {!isLoading &&
                            payments.map((row, i) => (
                                <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div className="font-semibold">{row._id}</div>
                                        <Tooltip
                                            content={
                                                row.status !== 'PENDING' ? (
                                                    <div className="px-4 py-3">
                                                        <p className="text-xs">{getStatusText(row.status)} By</p>
                                                        <div className="text-sm font-semibold mt-1">
                                                            {/* {row.method === 'RAZORPAY_GATEWAY'
                                                                ? 'Farepod Pay'
                                                                : row.actionBy?.name} */}
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            placement="bottom-start"
                                        >
                                            <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                                {getStatusText(row.status)}
                                            </StatusBadge>
                                        </Tooltip>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold text-blue-500">
                                            <Link to={`/sub-agencies/${row.subAgency._id}/details?tab=1`}>
                                                {row.subAgency.name}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {row.details?.transactionId
                                                ? row.details?.transactionId.toUpperCase()
                                                : '-'}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {' '}
                                            {row?.method ? getPaymentEnumLabel(row?.method) : '-'}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {row.details?.paymentMode
                                                ? getGatewayPaymentModeEnumLabel(row.details?.paymentMode)
                                                : '-'}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold whitespace-nowrap">
                                            {formatCurrency(row.amount)}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {row.details?.gatewayCharges
                                                ? formatCurrency(row.details?.gatewayCharges)
                                                : '-'}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        {moment(row.details.transactionDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                                        {/* <div className='font-semibold'>{row.subAgentRemark || '-'}</div> */}
                                    </td>
                                    <td className="td-1">
                                        {row.status === PaymentStatusEnum.PENDING ? (
                                            <Dropdown
                                                target={
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                actionHandler({
                                                                    payment: row,
                                                                    status: PaymentStatusEnum.APPROVED,
                                                                })
                                                            }
                                                        >
                                                            <span className="text-green-500">Approve</span>
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                actionHandler({
                                                                    payment: row,
                                                                    status: PaymentStatusEnum.REJECTED,
                                                                })
                                                            }
                                                        >
                                                            <span className="text-red-500">Reject</span>
                                                        </DropdownItem>
                                                    </DropdownList>
                                                }
                                            />
                                        ) : (
                                            <a className=" cursor-not-allowed  ">
                                                <MenuDotIcon />
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>

            {confirmationDialog.isOpen && (
                <ConfirmationModal
                    onClose={confirmationDialog.hide}
                    onConfirm={confirmationDialog.data.action}
                    show={confirmationDialog.isOpen}
                    isLoading={gatewayPaymentStatusUpdateMutation.isLoading}
                    actionType={confirmationDialog.data.type}
                    payment={confirmationDialog.data.payment}
                />
            )}

            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </>
    );
};

export default PaymentTable;
