import { Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { getAllFareSuppliers } from 'app/api/services/fare-supplier.service';

const commissionEnabledSupplier: Array<FareSupplierEnum> = [
    FareSupplierEnum.TRIP_JACK,
    FareSupplierEnum.FARE_BOUTIQUE,
    FareSupplierEnum.TRAVEL_BOUTIQUE,
    FareSupplierEnum.KAFILA,
    FareSupplierEnum.AIR_IQ,
    FareSupplierEnum.MTC,
    FareSupplierEnum.AGENCY_SERIES,
    FareSupplierEnum.MY_SERIES,
];

const CommissionLayout = () => {
    const suppliers: Array<FareSupplierEnum> = [];
    const fareSupplierQuery = useQuery(['fareSuppliers'], () => getAllFareSuppliers());

    if (fareSupplierQuery.isLoading) return <PageLoader />;

    commissionEnabledSupplier.forEach((cES) => {
        if (fareSupplierQuery.data.some((fs) => fs.identifier === cES)) {
            suppliers.push(cES);
        }
    });

    return (
        <Outlet
            context={{
                commissionPlanEnabledFareSupplier: suppliers,
            }}
        />
    );
};

export default CommissionLayout;
