import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { Status } from 'app/enums/status.enum';
import { mapFareSupplierStatusToBoolean } from '../../utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMemo } from 'react';

import { toast } from 'react-toastify';

interface IFareSupplierActivationToggle {
    handleToggleStatus: (status: boolean) => Promise<void>;
    checked: Status;
    fareSupplierLabel: string;
    isLoading: boolean;
}

const FareSupplierActivationToggle = (props: IFareSupplierActivationToggle) => {
    const { checked, handleToggleStatus, fareSupplierLabel, isLoading } = props;
    const confirmationDialog = useDialog<boolean>(null);

    const handleConfirmation = async (status: boolean) => {
        try {
            await handleToggleStatus(status);
            confirmationDialog.hide();
            toast.success(`${fareSupplierLabel} has been ${status ? 'activated' : 'deactivated'} successfully`);
        } catch (e: any) {
            toast.error(e?.data?.message || 'Something went wrong');
        }
    };

    return (
        <>
            <div>
                <input
                    type="checkbox"
                    className="form-switch success"
                    checked={mapFareSupplierStatusToBoolean(checked)}
                    onChange={(e) => confirmationDialog.show(e.target.checked)}
                />
            </div>
            <FareSupplierActivationToggleConfirmationModal
                show={confirmationDialog.isOpen}
                onClose={confirmationDialog.hide}
                fareSupplierLabel={fareSupplierLabel}
                isLoading={isLoading}
                action={confirmationDialog.data}
                handleConfirmation={handleConfirmation}
            />
        </>
    );
};

export default FareSupplierActivationToggle;

interface IFareSupplierActivationToggleConfirmationModal {
    show: boolean;
    isLoading: boolean;
    onClose: () => void;
    fareSupplierLabel: string;
    action: boolean;
    handleConfirmation: (status: boolean) => void;
}

const FareSupplierActivationToggleConfirmationModal = (props: IFareSupplierActivationToggleConfirmationModal) => {
    const { action, fareSupplierLabel, handleConfirmation } = props;

    const label: React.ReactNode = useMemo(() => {
        return (
            <div>
                Are you sure you want to <span className="font-bold">{action ? 'activate' : 'deactivate'}</span> the{' '}
                <span className="font-bold"> {fareSupplierLabel}</span> ?
            </div>
        );
    }, [fareSupplierLabel, action]);

    return (
        <Modal className={`px-8 py-6`} show={props.show} onClose={props.onClose} isLoading={props.isLoading}>
            <div className="text-gray-900 mt-4 text-center">{label}</div>
            <div className="flex justify-center gap-4 mt-4">
                <LoaderButton
                    className={`${action ? 'bg-green-500 ' : 'bg-red-500'}  text-white px-6 text-sm rounded-lg`}
                    onClick={() => handleConfirmation(action)}
                    isLoading={props.isLoading}
                    disabled={props.isLoading}
                >
                    Proceed
                </LoaderButton>
                <button
                    disabled={props.isLoading}
                    className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg"
                    onClick={props.onClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};
