import axios from 'axios';
import store from 'app/store';
import { authActions } from 'app/store/auth';

// axios instance for making requests
const http = axios.create();

// request interceptor for adding token
http.interceptors.request.use((config: any) => {
    const token = store.getState().auth.token;
      config.headers['aystav'] = 'awhsa';

    if (token) {
        // add token to request headers
        config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
});

http.interceptors.response.use(
    (response) => {
        localStorage.setItem('x-app-version', response.headers?.['x-app-version']);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            store.dispatch(authActions.logout());

            window.location.href = `/login`;
        }

        return Promise.reject(error.response);
    }
);

export default http;
