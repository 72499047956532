import { FC } from 'react';
import capitalize from 'lodash.capitalize';
import { formatDate, formatMinutesToTime, formatTime, SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import { getFormattedRefundableType, getRefundTypeBackgroundColor } from 'app/utils/flight-utils';

import { IFlightSegment } from 'app/types';
import moment from 'moment';

interface IFlightItinerary {
    segments: IFlightSegment[];
    cabinClass: string;
    refundableType: string;
    gdsPnr: string;
    createdAt: string;
}

const FlightItinerary: FC<IFlightItinerary> = (props) => {
    const { segments, refundableType, cabinClass, createdAt, gdsPnr } = props;

    return (
        <div className="mt-12 px-10">
            <h4 className="font-bold text-primary">Your Itinerary</h4>
            <div className="text-xs mt-2">
                Booked On :
                <span className="font-semibold mx-1">
                    {moment(createdAt, SYSTEM_DATE_TIME_FORMAT).format('DD MMM, YYYY')}
                </span>
                at
                <span className="font-semibold mx-1">
                    {moment(createdAt, SYSTEM_DATE_TIME_FORMAT).format('h:mm:ss a')}
                </span>
            </div>

            {gdsPnr && (
                <div className="text-xs mt-2">
                    PNR:
                    <span className="font-semibold mx-1">{gdsPnr}</span>
                </div>
            )}

            <div className="mt-10 text-xs space-y-6">
                {segments.map((segment: any, i: number) => (
                    <div key={i} className="grid grid-cols-5 overflow-hidden gap-4">
                        <div className="col-span-1">
                            <div className="min-w-max">
                                <div className="font-semibold">
                                    {segment.departure.airport.city} <span className="font-light"> to </span>{' '}
                                    {segment.arrival.airport.city}
                                </div>
                                <p className="mt-1 text-xs">{formatDate(segment.departure.date, 'dddd, MMM D')}</p>
                                <div className="flex flex-col mt-2 gap-1 items-start">
                                    <div className=" rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium bg-primary inline-block">
                                        {capitalize(cabinClass.toLowerCase())}
                                    </div>
                                    {refundableType && (
                                        <div
                                            className={` rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium inline-block ${getRefundTypeBackgroundColor(
                                                refundableType
                                            )} `}
                                        >
                                            {getFormattedRefundableType(refundableType)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 pl-2">
                            <div className="flex items-center gap-2 min-w-max">
                                <img crossOrigin="anonymous" className="w-8 h-8" src={segment.flight.logo} alt="" />
                                <div>
                                    <div className="font-semibold">{segment.flight.name}</div>
                                    <div className="text-xs text-black/70 mt-[2px]">
                                        {segment.flight.code} {segment.flight.number}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div>
                                <div className="text-xs text-black/70">
                                    {formatDate(segment.departure.date, 'dddd, D MMM')}
                                </div>
                                <div className="font-bold mt-[2px] text-normal">
                                    {formatTime(segment.departure.time)}
                                </div>
                            </div>
                            <div className="text-xs text-black/70 mt-[2px]">
                                {segment.departure.airport.name}
                                {segment.departure.airport.terminal && ', ' + segment.departure.airport.terminal}
                            </div>
                        </div>
                        <div className="col-span-1 flex flex-col items-center mt-1">
                            <div className="text-xs text-black/70">{formatMinutesToTime(segment.duration)}</div>
                            <div className="bg-[#ECAE0E] h-1 w-20 rounded-lg mt-1" />
                        </div>
                        <div className="col-span-1">
                            <div>
                                <div className="text-xs text-black/70">
                                    {formatDate(segment.arrival.date, 'dddd, D MMM')}
                                </div>
                                <div className="font-bold mt-[2px] text-normal">{formatTime(segment.arrival.time)}</div>
                            </div>
                            <div className="text-xs text-black/70 mt-[2px]">
                                {segment.arrival.airport.name}{' '}
                                {segment.arrival.airport.terminal && ', ' + segment.arrival.airport.terminal}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FlightItinerary;
