import React, { FC } from 'react';
import { formatDate, formatMinutesToTime, formatTime, SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import capitalize from 'lodash.capitalize';
import { getCabinClassColor } from 'app/utils/color-utils';
import TicketBarCode from '../Booking/BookingTicket/components/TicketBarCode/TicketBarCode';
import { IBookingPassenger } from 'app/types';
import TicketInformationList from '../Booking/BookingTicket/components/TicketInformationList/TicketInformationList';
import moment from 'moment';

interface TicketPdfProps {}

const TicketPdf: FC<TicketPdfProps> = (props) => {
    const { token } = useParams();

    const booking: any = jwtDecode(token);

    const getFormattedAddress = (address: any) => {
        return `${address.line}, ${address.city}, ${address.state}`;
    };

    const getAdultBaggage = () => {
        return booking.priceDetail.fareDescription.adult?.baggage;
    };

    const getChildBaggage = () => {
        return booking.priceDetail.fareDescription.child?.baggage;
    };

    const getInfantBaggage = () => {
        return booking.priceDetail.fareDescription.infant?.baggage;
    };

    const getCabinClass = () => booking.priceDetail.fareDescription.adult.cabinClass;

    const cabinColor = getCabinClass() ? getCabinClassColor(getCabinClass()) : 'bg-primary';

    const getPnrOfFirstSegment = (passenger: IBookingPassenger): string | undefined => {
        const selectedPnr = passenger.pnrs.find((pnr: any) =>
            (pnr.sector as string).startsWith(booking.departure.airport.code)
        );
        if (!selectedPnr) return null;
        return selectedPnr.number;
    };

    const barCodeData = {
        airlineCode: booking.departure.flight.code,
        flightNum: booking.departure.flight.number,
        DepartureDate: booking.departure.date,
        fromAirport: booking.departure.airport.code,
        toAirport: booking.arrival.airport.code,
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="px-10 pt-8">
                <div className="flex justify-between">
                    <img className="h-9" src={booking.agency.logo} alt="" />
                    <div className="flex flex-col text-right">
                        <div className="font-semibold text-primary">
                            {booking.subAgency ? booking.subAgency.name : booking.agency.name}
                        </div>
                        <div className="text-xs font-medium mt-1">
                            {booking.subAgency
                                ? getFormattedAddress(booking.subAgency.address)
                                : getFormattedAddress(booking.agency.address)}
                        </div>
                    </div>
                </div>

                {/* {Itinerary} */}
                <div className="mt-14">
                    <h4 className="font-bold text-primary">Your Itinerary</h4>
                    <div className="text-xs mt-2">
                        Booked On :{' '}
                        <span className="font-semibold mx-1">
                            {moment(booking.createdAt, SYSTEM_DATE_TIME_FORMAT).format('DD MMM, YYYY')}
                        </span>{' '}
                        at{' '}
                        <span className="font-semibold mx-1">
                            {moment(booking.createdAt, SYSTEM_DATE_TIME_FORMAT).format('h:mm:ss a')}
                        </span>
                    </div>

                    {booking.gdsPnr && (
                        <div className="text-xs mt-2">
                            PNR:
                            <span className="font-semibold mx-1">{booking.gdsPnr}</span>
                        </div>
                    )}

                    <div className="mt-12 text-xs">
                        {booking.segments.map((segment: any, i: number) => (
                            <div key={i} className="grid grid-cols-4 overflow-hidden">
                                <div className="col-span-1">
                                    <div className="min-w-max">
                                        <div className="font-semibold">
                                            {segment.departure.airport.city} <span className="font-light"> to </span>{' '}
                                            {segment.arrival.airport.city}
                                        </div>
                                        <p className="mt-1 text-xs">
                                            {formatDate(segment.departure.date, 'dddd, MMM D')}
                                        </p>
                                        <div
                                            className={` mt-2 rounded-full py-[0.4rem] px-3 leading-none text-white text-xs
                                                font-medium  inline-block ${cabinColor} `}
                                        >
                                            {capitalize(getCabinClass().toLowerCase())}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="flex items-center gap-2 min-w-max">
                                        <img
                                            crossOrigin="anonymous"
                                            className="w-8 h-8"
                                            src={segment.flight.logo}
                                            alt=""
                                        />
                                        <div>
                                            <div className="font-semibold">{segment.flight.name}</div>
                                            <div className="text-xs text-black/70 mt-[2px]">
                                                {segment.flight.code} {segment.flight.number}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="flex gap-3 min-w-max">
                                        <div>
                                            <div>
                                                <div className="text-xs text-black/70">
                                                    {formatDate(segment.departure.date, 'dddd, D MMM')}
                                                </div>
                                                <div className="font-bold mt-[2px] text-normal">
                                                    {formatTime(segment.departure.time)}
                                                </div>
                                            </div>
                                            <div className="text-xs text-black/70 mt-[2px]">
                                                {segment.departure.airport.name}
                                                {segment.departure.airport.terminal &&
                                                    ', ' + segment.departure.airport.terminal}
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center mt-1">
                                            <div className="text-xs text-black/70">
                                                {formatMinutesToTime(segment.duration)}
                                            </div>
                                            {/* Line */}
                                            <div className="bg-[#ECAE0E] h-1 w-20 rounded-lg mt-1" />
                                            {/*  */}
                                        </div>
                                        <div>
                                            <div>
                                                <div className="text-xs text-black/70">
                                                    {formatDate(segment.arrival.date, 'dddd, D MMM')}
                                                </div>
                                                <div className="font-bold mt-[2px] text-normal">
                                                    {formatTime(segment.arrival.time)}
                                                </div>
                                            </div>
                                            <div className="text-xs text-black/70 mt-[2px]">
                                                {segment.arrival.airport.name}{' '}
                                                {segment.arrival.airport.terminal &&
                                                    ', ' + segment.arrival.airport.terminal}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* {Passenger Details} */}
            <div>
                <div className="mt-12 px-10">
                    <div className=" font-semibold text-primary">Passenger Details</div>
                </div>

                <table className="w-full mt-6">
                    <thead>
                        <tr className="bg-primary/20">
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                                Name
                            </th>
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                                Airline PNR
                            </th>
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                                Ticket Number
                            </th>
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left" />
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                                Cabin
                            </th>
                            <th className="table-cell py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                                Baggage
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-sm">
                        {booking.passengers.adults.map((row: any, i: number) => (
                            <tr key={i}>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="flex flex-col">
                                        <div>
                                            {row.title} {row.firstName} {row.lastName}
                                        </div>
                                        <div className="text-xs font-normal">
                                            {getGenderFromTitle(row.title)}, Adult
                                        </div>
                                    </div>
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="space-y-1">
                                        {row.pnrs.map((pnr: any, i: number) => (
                                            <div key={i}>
                                                <div>{pnr.number}</div>
                                                <div className="text-[0.7rem] leading-none font-normal">
                                                    {pnr.sector}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>

                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="space-y-1">
                                        {row.ticketNumbers.map((ticketNumber: any, i: number) => (
                                            <div key={i}>
                                                <div className="text-xs">{ticketNumber.number}</div>
                                                <div className="text-[0.7rem] leading-none font-normal">
                                                    {ticketNumber.sector}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TicketBarCode
                                        firstName={row.firstName}
                                        lastName={row.lastName}
                                        pnr={getPnrOfFirstSegment(row)}
                                        {...barCodeData}
                                    />
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getAdultBaggage()?.cabin}
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getAdultBaggage()?.checkIn}
                                </td>
                            </tr>
                        ))}
                        {booking.passengers.children.map((row: any, i: number) => (
                            <tr key={i}>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="flex flex-col">
                                        <div>
                                            {row.title} {row.firstName} {row.lastName}
                                        </div>
                                        <div className="text-[0.7rem] leading-none font-normal">
                                            {getGenderFromTitle(row.title)}, Child
                                        </div>
                                    </div>
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="space-y-1">
                                        {row.pnrs.map((pnr: any, i: number) => (
                                            <div key={i}>
                                                <div>{pnr.number}</div>
                                                <div className="text-[0.7rem] leading-none font-normal">
                                                    {pnr.sector}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TicketBarCode
                                        firstName={row.firstName}
                                        lastName={row.lastName}
                                        pnr={getPnrOfFirstSegment(row)}
                                        {...barCodeData}
                                    />
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getChildBaggage()?.cabin}
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getChildBaggage()?.checkIn}
                                </td>
                            </tr>
                        ))}
                        {booking.passengers.infants.map((row: any, i: number) => (
                            <tr key={i}>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="flex flex-col">
                                        <div>
                                            {row.title} {row.firstName} {row.lastName}
                                        </div>
                                        <div className="text-[0.7rem] leading-none font-normal">
                                            {getGenderFromTitle(row.title)}, Infant (DOB -{' '}
                                            {formatDOB(row.dob, 'YYYY-MM-DD')})
                                        </div>
                                    </div>
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    <div className="space-y-1">
                                        {row.pnrs.map((pnr: any, i: number) => (
                                            <div key={i}>
                                                <div>{pnr.number}</div>
                                                <div className="text-[0.7rem] leading-none font-normal">
                                                    {pnr.sector}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TicketBarCode
                                        firstName={row.firstName}
                                        lastName={row.lastName}
                                        pnr={getPnrOfFirstSegment(row)}
                                        {...barCodeData}
                                    />
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getInfantBaggage()?.cabin}
                                </td>
                                <td className="table-cell py-4 px-3 align-middle first:pl-10 last:pr-10 font-semibold">
                                    {getInfantBaggage()?.checkIn}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* {Fare details} */}
            <div>
                <div className="mt-12 px-10">
                    <div className="text-base font-semibold text-primary">Fare Details</div>
                    <div className="mt-5 grid grid-cols-3">
                        <div className="col-span-1">
                            <div className=" text-sm flex justify-between">
                                <div>Base Fare</div>
                                <div>{formatCurrency(booking.priceDetail.totalBaseFare)}</div>
                            </div>
                            <div className=" text-sm flex justify-between mt-2">
                                <div>Fees & Surcharges</div>
                                <div>
                                    {formatCurrency(
                                        booking.priceDetail.totalTaxesAndFees + booking.taxDetails.managementFeesTotal
                                    )}
                                </div>
                            </div>
                            {/* <div className=" text-sm flex justify-between mt-2">
                                <div>Discounts</div>
                                <div className="text-primary">₹ 123</div>
                            </div> */}
                            <div className=" text-sm flex justify-between mt-4 font-semibold">
                                <div>Total Amount</div>
                                <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
                            </div>
                        </div>
                        <div className="row-span-1"></div>
                    </div>
                </div>
            </div>

            {/* {Important information} */}
            {/* <div className='mb-6'>
                <div className='mt-12 px-10'>
                    <div className='text-base font-semibold text-primary'>Important Information</div>
                    <ul className='mt-5 list-disc list-inside text-[9px]'>
                        <li>
                            Check-in Time : Passengers are advised to reach airport 2-3 hrs before departure. Check-in
                            procedure and baggage drop will close 1 hour before departure
                        </li>
                        <li className='mt-2'>
                            DGCA passenger charter : Please refer to passenger charter on the DGCA website.
                        </li>
                        <li className='mt-2'>
                            Please do not share your personal banking and security details like passwords, CVV, etc.
                            with any third person. For any query, please reach out to us on our official customer care
                            number.
                        </li>
                        <li className='mt-2'>
                            Valid ID proof needed : Carry a valid photo identification proof (Driver Licence, Aadhar
                            Card, Pan Card or any other Government recognised photo identification)
                        </li>
                        <li className='mt-2'>For date-change requests please contact our customer care.</li>
                    </ul>
                </div>
            </div> */}

            {/* {Important information} */}
            <TicketInformationList htmlContent={booking.tnc.content} />

            {/* {Helpline} */}
            {/* <div>
                <div className="mt-12 px-10 mb-6">
                    <div className="text-sm font-semibold text-primary">
                        {booking.agency.email} | {booking.agency.website}
                    </div>
                </div>
            </div> */}

            {/* {Final Logo} */}
            {/* <div className="pb-6 mt-auto">
                <div className="mt-2 px-10 flex flex-col items-end ">
                    <div className="text-[.7rem]">Travel Technology Partner</div>
                    <img className="h-6 inline-block mt-2" src="/icons/logo.svg" alt="" />
                </div>
            </div> */}
        </div>
    );
};

export default TicketPdf;

// Test data
// const booking: any = {
//     priceDetail: {
//         fareDescription: {
//             adult: {
//                 fareComponent: {
//                     net: 3100,
//                     base: 3000,
//                     taxesAndFees: 100,
//                     total: 3100,
//                 },
//                 baggage: {
//                     checkIn: '7 Kg',
//                     cabin: '15 Kg',
//                 },
//                 taxesAndFees: [
//                     {
//                         name: 'Tax',
//                         value: 100,
//                         _id: '6389d94af620b3908540474c',
//                     },
//                 ],
//                 refundableType: 'NON_REFUNDABLE',
//                 cabinClass: 'ECONOMY',
//                 mealIndicator: false,
//             },
//             child: {
//                 fareComponent: {
//                     net: 3100,
//                     base: 3000,
//                     taxesAndFees: 100,
//                     total: 3100,
//                 },
//                 baggage: {
//                     checkIn: '7 Kg',
//                     cabin: '15 Kg',
//                 },
//                 taxesAndFees: [
//                     {
//                         name: 'Tax',
//                         value: 100,
//                         _id: '6389d94af620b3908540474d',
//                     },
//                 ],
//                 refundableType: 'NON_REFUNDABLE',
//                 cabinClass: 'ECONOMY',
//                 mealIndicator: false,
//             },
//             infant: {
//                 taxesAndFees: [],
//             },
//         },
//         additionalMarkup: 0,
//         id: 'AUX5890571SE',
//         fareIdentifier: 'Series P',
//         totalFare: 3100,
//         totalBaseFare: 3000,
//         totalTaxesAndFees: 100,
//         additionalMarkupHistory: [],
//     },
//     passengers: {
//         adults: [
//             {
//                 title: 'Mr',
//                 firstName: 'Hannan',
//                 lastName: 'Seab',
//                 type: 'ADULT',
//                 amountPaid: 3100,
//                 pnrs: [
//                     {
//                         sector: 'SXR-DEL',
//                         number: 'DCLPV',
//                     },
//                 ],
//                 ticketNumbers: [],
//                 seatNumbers: [],
//                 booking: 'AUX2003991T',
//             },
//         ],
//         children: [],
//         infants: [],
//     },
//     payment: {
//         type: 'SUB_AGENCY_WALLET',
//         amount: 3100,
//     },
//     markup: 0,
//     _id: 'AUX2003991T',
//     segments: [
//         {
//             flight: {
//                 name: 'SpiceJet',
//                 code: 'SG',
//                 number: '8964',
//                 logo: 'http://localhost:3001/airline-logos/SG.png',
//             },
//             duration: 90,
//             layoverDuration: 0,
//             arrival: {
//                 date: '20221203',
//                 time: '153500',
//                 airport: {
//                     code: 'DEL',
//                     name: 'Delhi Indira Gandhi Intl',
//                     city: 'Delhi',
//                     cityCode: 'DEL',
//                     country: 'India',
//                     countryCode: 'IN',
//                     terminal: '',
//                 },
//             },
//             departure: {
//                 date: '20221203',
//                 time: '140500',
//                 airport: {
//                     code: 'SXR',
//                     name: 'Srinagar Arpt',
//                     city: 'Srinagar',
//                     cityCode: 'SXR',
//                     country: 'India',
//                     countryCode: 'IN',
//                     terminal: '',
//                 },
//             },
//             _id: '6389d94af620b3908540474b',
//         },
//     ],
//     bookingId: 'AUX5890571SE',
//     agency: {
//         _id: 'AUX1233Y',
//         name: 'Travel Companion',
//         email: 'help@travelcompanion.co',
//         website: 'agents.travelcompanion.co',
//         logo: 'AUX1233Y-1669898489603.png',
//         address: {
//             line: 'MA Road',
//             country: 'India',
//             state: 'Jammu & Kashmir',
//             city: 'Srinagar',
//             pincode: '190001',
//         },
//         logoUrl: 'http://localhost:3001/agency-logos/AUX1233Y-1669898489603.png',
//         iconUrl: 'http://localhost:3001/agency-icons/undefined',
//         id: 'AUX1233Y',
//     },
//     subAgency: {
//         _id: 'AUX10001',
//         name: 'Starlink  Tours & Travel',
//         email: 'starlinks@gmail.com',
//         address: {
//             line: 'MA Road',
//             country: 'India',
//             state: 'Jammu & Kashmir',
//             city: 'Srinagar',
//             pincode: '190008',
//         },
//         logo: 'AUX10001-1671526320880.webp',
//         logoUrl: 'http://localhost:3001/sub-agency-logos/AUX10001-1671526320880.webp',
//         id: 'AUX10001',
//     },
//     subAgencyUser: 'AUX1234USR-SA',
//     agencyUser: null,
//     duration: '90',
//     fareSupplier: '0',
//     contactDetails: {
//         mobileNumber: '9797790498',
//         email: 'darhannan5@gmail.com',
//     },
//     status: 'COMPLETED',
//     createdAt: '20221202162402',
//     updatedAt: '2022-12-02T10:54:09.917Z',
//     departure: {
//         date: '20221203',
//         time: '140500',
//         airport: {
//             code: 'SXR',
//             name: 'Srinagar Arpt',
//             city: 'Srinagar',
//             cityCode: 'SXR',
//             country: 'India',
//             countryCode: 'IN',
//             terminal: '',
//         },
//         flight: {
//             name: 'SpiceJet',
//             code: 'SG',
//             number: '8964',
//             logo: 'http://localhost:3001/airline-logos/SG.png',
//         },
//     },
//     arrival: {
//         date: '20221203',
//         time: '153500',
//         airport: {
//             code: 'DEL',
//             name: 'Delhi Indira Gandhi Intl',
//             city: 'Delhi',
//             cityCode: 'DEL',
//             country: 'India',
//             countryCode: 'IN',
//             terminal: '',
//         },
//         flight: {
//             name: 'SpiceJet',
//             code: 'SG',
//             number: '8964',
//             logo: 'http://localhost:3001/airline-logos/SG.png',
//         },
//     },
//     id: 'AUX2003991T',
// };
