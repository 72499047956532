import { RouteObject } from 'react-router-dom';
import { FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';
import CommissionPlanRoutes from 'app/modules/fare-management/CommissionPlanRoutes';

import FareSupplierMtc from 'app/pages/FareSupplier/Mtc/FareSupplierMtc';
import FareSupplierList from 'app/pages/FareSupplier/FareSupplierList/FareSupplierList';
import FareSupplierAiriq from 'app/pages/FareSupplier/FareSupplierAiriq/FareSupplierAiriq';
import FareSupplierKafila from 'app/pages/FareSupplier/FareSupplierKafila/FareSupplierKafila';
import FareSupplierTripJack from 'app/pages/FareSupplier/FareSupplierTripJack/FareSupplierTripJack';
import FareSupplierMySeries from 'app/pages/FareSupplier/FareSupplierMySeries/FareSupplierMySeries';
import FareSupplierAgencySeries from 'app/pages/FareSupplier/FareSupplierAgencySeries/FareSupplierAgencySeries';
import FareSupplierFareBoutique from 'app/pages/FareSupplier/FareSupplierFareBoutique/FareSupplierFareBoutique';
import FareSupplierTravelBoutique from 'app/pages/FareSupplier/FareSupplierTravelBoutique/FareSupplierTravelBoutique';

const FareSupplierRoutes: Array<RouteObject> = [
    {
        path: 'fare-management',
        children: [
            {
                index: true,
                path: 'fare-suppliers',
                element: <FareSupplierList />,
            },
            {
                path: `fare-suppliers/${FareSupplierUrlEnum.TRIP_JACK}`,
                element: <FareSupplierTripJack />,
            },
            { path: `fare-suppliers/${FareSupplierUrlEnum.AIR_IQ}`, element: <FareSupplierAiriq /> },
            {
                path: `fare-suppliers/${FareSupplierUrlEnum.TRAVEL_BOUTIQUE}`,
                element: <FareSupplierTravelBoutique />,
            },
            { path: `fare-suppliers/${FareSupplierUrlEnum.KAFILA}`, element: <FareSupplierKafila /> },
            {
                path: `fare-suppliers/${FareSupplierUrlEnum.FARE_BOUTIQUE}`,
                element: <FareSupplierFareBoutique />,
            },
            { path: `fare-suppliers/${FareSupplierUrlEnum.MTC}`, element: <FareSupplierMtc /> },
            {
                path: `fare-suppliers/${FareSupplierUrlEnum.AGENCY_SERIES}`,
                element: <FareSupplierAgencySeries />,
            },
            { path: `fare-suppliers/${FareSupplierUrlEnum.MY_SERIES}`, element: <FareSupplierMySeries /> },

            ...CommissionPlanRoutes,
        ],
    },
];

export default FareSupplierRoutes;
