import * as Yup from 'yup';
import moment from 'moment';
import Modal from '../Modal/Modal';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import capitalize from 'lodash.capitalize';
import { EditPencilIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { IAmendmentMessage } from 'app/types';
import StatusBadge from '../StatusBadge/StatusBadge';
import { AMENDMENT_STATUS } from 'app/utils/constants';
import LoaderButton from '../LoaderButton/LoaderButton';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAmendmentStatusColor, getAmendmentStatusText, getInitials } from 'app/utils/common-utils';
import AmendmentActionDialog from 'app/pages/Booking/BookingDetail/components/AmendmentActionDialog/AmendmentActionDialog';
import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';

const CREDIT = 'CREDIT';
const DEBIT = 'DEBIT';

const updateChargeFormSchema = Yup.object().shape({
    amount: Yup.string()
        .trim()
        .nullable()
        .required('This field is required')
        .matches(/^\d+$/, 'Please enter a valid amount'),
});

interface AmendmentChatProps {
    show: boolean;
    onClose: () => void;
    bookingId: string;
    amendmentId: string;
    refetchQueryIdentifier: Array<string | number>;
}

const AmendmentChat: FC<AmendmentChatProps> = (props) => {
    const { onClose, show, bookingId, amendmentId, refetchQueryIdentifier } = props;
    const chatBox = useRef<HTMLDivElement>(null);
    const queryClient = useQueryClient();
    const rejectDialog = useDialog();

    const [editAmount, setEditAmount] = useState(false);

    const amendmentQuery = useQuery(['amendment', bookingId, amendmentId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments/${amendmentId}`
        );
        return data;
    });

    const conversationQuery = useQuery(['amendment', 'chat', bookingId, amendmentId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments/${amendmentId}/messages`
        );
        return data;
    });

    const actionDialog = useDialog<{
        amendmentId: '';
        bookingId: '';
    }>({
        amendmentId: '',
        bookingId: '',
    });

    //  <AmendmentActionDialog  onClose={} show={} />;

    const messageMutation = useMutation(async (values: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments/${amendmentId}/message`,
            values
        );

        return data;
    });

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await messageMutation.mutateAsync({
                ...values,
            });
            formikHelpers.resetForm();
            queryClient.fetchQuery(['amendment', 'chat', bookingId, amendmentId]);
            queryClient.fetchQuery(['amendment', bookingId, amendmentId]);
            queryClient.fetchQuery(['pendingAmendments']);
            queryClient.fetchQuery(refetchQueryIdentifier);
        } catch (ex: any) {
            // toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            // onClose();
        }
    };

    const updateAmountMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments/${amendmentId}/amount`,
            payload
        );

        return data;
    });

    const handleUpdateAmount = async (values: any) => {
        try {
            const updatedAmendment = await updateAmountMutation.mutateAsync(values);
            toast.success('Amount added successfully.');

            queryClient.setQueryData(['amendment', bookingId, amendmentId], (prev: any) => {
                return updatedAmendment;
            });
            queryClient.fetchQuery(['amendment', 'chat', bookingId, amendmentId]);
            queryClient.fetchQuery(['pendingAmendments']);
            queryClient.fetchQuery(refetchQueryIdentifier);

            setEditAmount(false);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const updateStatusMutation = useMutation(async () => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments/${amendmentId}/reject`
        );

        return data;
    });

    const handleStatus = async () => {
        try {
            const updatedAmendment = await updateStatusMutation.mutateAsync();
            toast.success('Amendment status updated successfully.');

            queryClient.setQueryData(['amendment', bookingId, amendmentId], (prev: any) => {
                return updatedAmendment;
            });
            queryClient.fetchQuery(['amendment', 'chat', bookingId, amendmentId]);
            queryClient.fetchQuery(['pendingAmendments']);
            queryClient.fetchQuery(refetchQueryIdentifier);
            rejectDialog.hide();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    useLayoutEffect(() => {
        if (chatBox.current) {
            chatBox.current.scrollTop = chatBox.current.scrollHeight;
        }
    });

    const paymentTypes = [
        {
            label: 'Amount to be refunded ',
            value: CREDIT,
        },
        {
            label: 'Amount to be charged',
            value: DEBIT,
        },
    ];

    if (amendmentQuery.isLoading || conversationQuery.isLoading) return <p></p>;

    // <div className="flex justify-center items-center pt-14">
    //     <Spinner />
    // </div>

    const amendment = amendmentQuery.data;
    const conversation = conversationQuery.data;

    return (
        <>
            <Modal className=" max-w-3xl pt-10  px-8" show={show} onClose={onClose}>
                <div className="flex flex-col h-[650px]">
                    <div>
                        <StatusBadge className="mt-1" color={getAmendmentStatusColor(amendment.status)}>
                            {getAmendmentStatusText(amendment.status, amendment.subAgency.name)}
                        </StatusBadge>
                        <div className="mt-6">
                            <div className="grid grid-cols-6 mt-2 gap-5">
                                <div className="col-span-2 sm:col-span-1 text-sm leading-4 font-semibold ">
                                    Request ID
                                </div>
                                <div className="col-span text-sm leading-4 font-normal text-blue-500">
                                    {amendment.id}
                                </div>
                            </div>
                            <div className="grid grid-cols-6 mt-2 gap-5">
                                <div className="col-span-2 sm:col-span-1 text-sm leading-4 font-semibold">
                                    Request By
                                </div>
                                <div className="col-span-4 sm:col-span-5 text-sm leading-4 font-normal">
                                    {amendment.raisedBy}
                                </div>
                            </div>
                            <div className="grid grid-cols-6 mt-2 gap-5">
                                <div className="col-span-2 sm:col-span-1 text-sm leading-4 font-semibold">
                                    Date & Time
                                </div>
                                <div className="col-span-4 sm:col-span-5 text-sm leading-4 font-normal">
                                    {`${moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')} at
                        ${moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}`}
                                </div>
                            </div>
                            <div className="grid grid-cols-6 mt-2 gap-5">
                                <div className="col-span-2 sm:col-span-1 text-sm leading-4 font-semibold">
                                    Change Type
                                </div>
                                <div className="col-span-4 sm:col-span-5 text-sm leading-4 font-normal">
                                    {capitalize(amendment.type.toLowerCase())}
                                </div>
                            </div>

                            <div className=" mt-4 leading-4 font-bold">Conversation</div>
                        </div>
                    </div>
                    <div ref={chatBox} className="flex-grow flex flex-col overflow-auto scroll-smooth pr-3 mt-2 ">
                        {conversation.map((message: IAmendmentMessage, index: number) => {
                            if (message.type === 'message') {
                                return (
                                    <div
                                        key={`${message.createdAt}`}
                                        className={`mt-6 inline-block ${
                                            message.userType === 'AgencyUser' ? 'self-end ml-20' : 'self-start mr-20'
                                        }`}
                                    >
                                        <div
                                            className={`inline-block p-4 pt-3  rounded-3xl w-full  text-sm leading-4 font-normal     
                                ${message.userType === 'AgencyUser' ? 'bg-[#0067FF] text-white' : 'bg-[#D9D9D9]'}
                                `}
                                        >
                                            {message.content}
                                        </div>
                                        <div className="mt-4 flex gap-2 justify-start items-center">
                                            <div className="w-5 h-5 bg-primary/10 rounded-full flex items-center justify-center text-[.5rem]">
                                                <div className="text-primary font-semibold">
                                                    {getInitials(message.by.name)}
                                                </div>
                                            </div>
                                            <div className="text-xs leading-[14px] font-normal">
                                                {`${message.by.name} at ${moment(
                                                    message.createdAt,
                                                    'YYYYMMDDHHmmss'
                                                ).format('hh:mm A - DD MMM, YY')}`}
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if (message.type === 'action') {
                                return (
                                    <div key={`${message.createdAt}`} className={`mt-6 self-center px-4 group`}>
                                        <div
                                            className={`inline-block p-4 pt-3 rounded-3xl w-full text-sm leading-4 font-normal bg-yellow-100`}
                                        >
                                            {message.content}
                                        </div>
                                        {/* <div className="mt-4 flex gap-2 justify-start items-center hidden group-hover:flex">
                                        <div className="w-5 h-5 bg-primary/10 rounded-full flex items-center justify-center text-[.5rem]">
                                            <div className="text-primary font-semibold">
                                                {getInitials(message.by.name)}
                                            </div>
                                        </div>
                                        <div className="text-xs leading-[14px] font-normal">
                                            {`${message.by.name} at ${moment(
                                                message.createdAt,
                                                'YYYYMMDDHHmmss'
                                            ).format('hh:mm A - DD MMM, YY')}`}
                                        </div>
                                    </div> */}
                                    </div>
                                );
                            } else return '';
                        })}
                    </div>
                    <div>
                        {/* message section */}
                        {amendment.status !== AMENDMENT_STATUS.REJECTED &&
                            amendment.status !== AMENDMENT_STATUS.COMPLETED && (
                                <div className="mt-8">
                                    <Formik initialValues={{ message: '' }} onSubmit={handleSubmit}>
                                        <Form>
                                            <div className="flex gap-4">
                                                <Field
                                                    name="message"
                                                    type="text"
                                                    className="w-full rounded-3xl border outline-none px-4 py-3 border-[#6F6F6F] "
                                                    placeholder="Write a reply..."
                                                />
                                                <button
                                                    type="submit"
                                                    className="text-sm font-semibold bg-primary text-white px-6 py-4 rounded-3xl"
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            )}
                        {/* line */}
                        <div className=" mt-6 border bottom-1 border-gray-200" />
                        {/* amount decided */}

                        {amendment.status !== AMENDMENT_STATUS.COMPLETED &&
                            amendment.status !== AMENDMENT_STATUS.REJECTED && (
                                <div className="flex items-center">
                                    <Formik
                                        // initialValues={{ amount: amendment.amount === null ? '' : amendment.amount }}
                                        initialValues={{
                                            amount: amendment.amount === null ? '' : amendment.amount,
                                            noteType: amendment.noteType,
                                        }}
                                        onSubmit={handleUpdateAmount}
                                        validationSchema={updateChargeFormSchema}
                                    >
                                        {({ values, setFieldValue, errors, touched }) => (
                                            <Form>
                                                <div className="flex py-6 justify-between gap-5">
                                                    <div className="flex items-center gap-4 text-lg leading-5 font-semibold">
                                                        <div>Total Amount</div>
                                                        {editAmount || amendment.amount === null ? (
                                                            <>
                                                                <div>
                                                                    <div className="relative flex items-center">
                                                                        <Field
                                                                            name="amount"
                                                                            type="text"
                                                                            className="rounded-md border outline-none p-2 pl-6 border-gray-200 w-36 py-3 px-2  "
                                                                        />
                                                                        <div className="absolute pl-2">₹</div>
                                                                    </div>
                                                                    <div className="form-error">
                                                                        <ErrorMessage name="amount" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-col gap-1 ">
                                                                    {' '}
                                                                    {paymentTypes.map((row) => (
                                                                        <div key={row.value} className="flex gap-2">
                                                                            <Field
                                                                                type="radio"
                                                                                id={row.value}
                                                                                name="method"
                                                                                onChange={() =>
                                                                                    setFieldValue('noteType', row.value)
                                                                                }
                                                                                checked={row.value === values.noteType}
                                                                                className="accent-primary"
                                                                                // disabled={row.value === CREDIT}
                                                                            />
                                                                            <label
                                                                                className={'text-sm block '}
                                                                                htmlFor={row.value}
                                                                            >
                                                                                {row.label}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="flex items-center gap-4">
                                                                {`₹ ${amendment.amount}`}{' '}
                                                                {amendment.status !== 'REJECTED' &&
                                                                    amendment.status !== 'APPROVED_BY_SUB_AGENCY' &&
                                                                    amendment.status !== 'COMPLETED' && (
                                                                        <div onClick={() => setEditAmount(true)}>
                                                                            {' '}
                                                                            <EditPencilIcon />{' '}
                                                                        </div>
                                                                    )}
                                                                <div className="ml-4">
                                                                    {amendment.noteType === DEBIT
                                                                        ? 'Amount to be charged'
                                                                        : 'Amount to be refunded'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {amendment.status !== 'APPROVED_BY_SUB_AGENCY' &&
                                                        (editAmount || amendment.amount === null) && (
                                                            <div className="flex gap-4 items-center">
                                                                <LoaderButton
                                                                    isLoading={updateAmountMutation.isLoading}
                                                                    type="submit"
                                                                    className="text-white bg-green-500 px-4 py-3 rounded-md text-base leading-5 font-semibold min-w-[120px] "
                                                                >
                                                                    {amendment.amount === null
                                                                        ? 'Add Amount'
                                                                        : 'Update Amount'}
                                                                </LoaderButton>
                                                                {/* <button
                                                    onClick={handleStatus}
                                                    className="text-white bg-red-500 px-4 py-3 rounded-md text-base leading-5 font-semibold "
                                                >
                                                    Reject Change
                                                </button> */}
                                                            </div>
                                                        )}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    {actionDialog.isOpen && (
                                        <AmendmentActionDialog
                                            amendmentId={actionDialog.data.amendmentId}
                                            bookingId={actionDialog.data.bookingId}
                                            onClose={() => {
                                                actionDialog.hide();
                                                queryClient.fetchQuery(['amendment', bookingId, amendmentId]);
                                                queryClient.fetchQuery(refetchQueryIdentifier);
                                            }}
                                            show={actionDialog.isOpen}
                                        />
                                    )}
                                </div>
                            )}

                        {amendment.status !== AMENDMENT_STATUS.COMPLETED &&
                            amendment.status !== AMENDMENT_STATUS.REJECTED && (
                                <div className="flex gap-4 items-center border-t bottom-1 py-3 border-gray-200 ">
                                    <LoaderButton
                                        isDisabled={amendment.amount === null}
                                        className="w-1/2 text-white disabled:black bg-green-500 px-4 py-3 rounded-md text-base leading-5 font-semibold"
                                        onClick={() =>
                                            actionDialog.show({
                                                amendmentId: amendment._id,
                                                bookingId: amendment.booking,
                                            })
                                        }
                                    >
                                        Approve Amendment
                                    </LoaderButton>

                                    <LoaderButton
                                        onClick={rejectDialog.show}
                                        isLoading={updateStatusMutation.isLoading}
                                        type="button"
                                        className=" w-1/2 text-white bg-red-500 px-4 py-3 rounded-md text-base leading-5 font-semibold "
                                    >
                                        Reject
                                    </LoaderButton>
                                </div>
                            )}
                    </div>
                </div>
            </Modal>
            <RejectAmendmentDialog
                show={rejectDialog.isOpen}
                onClose={rejectDialog.hide}
                isLoading={updateStatusMutation.isLoading}
                onConfirm={handleStatus}
            />
        </>
    );
};

interface RejectAmendmentDialogProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading: boolean;
}

const RejectAmendmentDialog: FC<RejectAmendmentDialogProps> = (props) => {
    return (
        <Modal className="px-10 py-10" show={props.show} onClose={props.onClose}>
            <div className="text-gray-900 mt-4 text-center">Are you sure you want to reject this amendment</div>
            <div className="flex justify-center gap-4 mt-6">
                <LoaderButton
                    className="bg-red-500 text-white px-6 text-sm rounded-lg"
                    onClick={props.onConfirm}
                    isLoading={props.isLoading}
                >
                    Yes, Reject
                </LoaderButton>
                <button className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg" onClick={props.onClose}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default AmendmentChat;
