export const rgbToHex = (rgb: string) => {
    const [r, g, b] = rgb.split(' ');

    return (
        '#' +
        [r, g, b]
            .map((val) => {
                const x = +val;
                const hex = x.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            })
            .join('')
    );
};

export const hexToRgb = (hex: string) => {
    const hexArr = hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g);
    const rgbArr = hexArr.map((x) => parseInt(x, 16));

    return rgbArr.join(' ');
};

export const getCabinClassColor = (cabinClass: string) => {
    let color = 'bg-black';
    switch (cabinClass) {
        case 'ECONOMY':
            color = 'bg-[#2a4d9c]';
            break;
        case 'PREMIUM_ECONOMY':
            color = 'bg-[#2a4d9c]';
            break;
        case 'PRIVATE':
            color = 'bg-[#2a4d9c]';
            break;
        case 'BUSINESS':
            color = 'bg-[#c29645]';
            break;
        case 'FIRST':
            color = 'bg-[#c29645]';
            break;
    }
    return color;
};
