import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    getFareSupplierFareIdentifierMapping,
    updateFareIdentifierMapping,
} from 'app/api/services/fare-supplier.service';
import { IFareIdentifierMapping } from '../../types/fare-identifier-mapping';
import Spinner from 'app/components/Spinner/Spinner';
import { DeleteIcon, EditPencilIcon, InfoCircleIcon, PlusIcon } from 'app/icons';
import Modal from 'app/components/Modal/Modal';
import useDialog from 'app/hooks/use-dialog';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Tooltip from 'app/components/Tooltip/Tooltip';
import * as Yup from 'yup';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import Color from 'color';
import { FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';

interface IFareIdentifierPreview {
    fareIdentifier: string;
    backGroundColor: string;
}

const FareIdentifierPreview: FC<IFareIdentifierPreview> = (props) => {
    const { backGroundColor, fareIdentifier } = props;
    const [textColor, setTextColor] = useState('white');

    useEffect(() => {
        const secondaryColorObj = Color(backGroundColor);
        if (secondaryColorObj.isLight()) {
            setTextColor('black');
        }
    }, []);

    return (
        <div className="flex">
            <div
                style={{ background: backGroundColor, color: textColor }}
                className={` rounded-md px-2 py-1 font-bold text-xs leading-[12px]  `}
            >
                {fareIdentifier}
            </div>
        </div>
    );
};

interface IFareIdentifierMappingForm {
    fareSupplier: FareSupplierUrlEnum;
}

const FareIdentifierMappingForm: FC<IFareIdentifierMappingForm> = (props) => {
    const { fareSupplier } = props;
    const queryClient = useQueryClient();
    const createDialog = useDialog();
    const editDialog = useDialog<{ mapping: IFareIdentifierMapping; index: number }>(null);
    const deleteDialog = useDialog<number>(null);

    const { data: FareIdentifierMappings, isLoading } = useQuery<Array<IFareIdentifierMapping>>(
        ['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier],
        () => getFareSupplierFareIdentifierMapping(fareSupplier)
    );

    const updateFareIdentifierMappingMutation = useMutation(
        (payload: { payload: Array<IFareIdentifierMapping>; fareSupplier: FareSupplierUrlEnum }) =>
            updateFareIdentifierMapping(payload)
    );

    const handleAddFareIdentifierMapping = async (FareIdentifierMap: IFareIdentifierMapping) => {
        const existingMaps = produce(FareIdentifierMappings, (draft) => {
            // draft.forEach((item) => {
            //     delete item._id;
            // });
            draft.push(FareIdentifierMap);
            return draft;
        });
        // existingMaps.push(FareIdentifierMap);
        try {
            await updateFareIdentifierMappingMutation.mutateAsync({
                payload: existingMaps,
                fareSupplier: fareSupplier,
            });
            queryClient.setQueryData(['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier], () => {
                return existingMaps;
            });
            toast.success('Fare Identifier Mapping added successfully');
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    const handleUpdateFareIdentifierMapping = async (FareIdentifierMap: IFareIdentifierMapping, index: number) => {
        const existingMaps = produce(FareIdentifierMappings, (draft) => {
            draft[index].mappedIdentifier = FareIdentifierMap.mappedIdentifier;
            draft[index].supplierIdentifier = FareIdentifierMap.supplierIdentifier;
            draft[index].isInstantPnr = FareIdentifierMap.isInstantPnr;
            draft[index].fareIdentifierLabelColor = FareIdentifierMap.fareIdentifierLabelColor;
            return draft;
        });
        try {
            await updateFareIdentifierMappingMutation.mutateAsync({
                payload: existingMaps,
                fareSupplier: fareSupplier,
            });
            queryClient.setQueryData(['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier], () => {
                return existingMaps;
            });
            toast.success('Fare Identifier Mapping updated successfully');
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    const handleDeleteFareIdentifierMapping = async (index: number) => {
        const existingMaps = produce(FareIdentifierMappings, (draft) => {
            draft.splice(index, 1);
            return draft;
        });

        try {
            await updateFareIdentifierMappingMutation.mutateAsync({
                payload: existingMaps,
                fareSupplier: fareSupplier,
            });
            queryClient.setQueryData(['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier], () => {
                return existingMaps;
            });
            toast.success('Fare Identifier Mapping deleted successfully');
            deleteDialog.hide();
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    const handleEnabledChange = async (index: number, isChecked: boolean) => {
        queryClient.setQueryData(['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier], (prevValues: any) => {
            return produce(FareIdentifierMappings, (draft) => {
                draft[index].isInstantPnr = isChecked;
                return draft;
            });
        });

        try {
            const previousMappings = queryClient.getQueryData<Array<IFareIdentifierMapping>>([
                'FARE_SUPPLIER',
                'FARE_IDENTIFIER_MAPPING',
                fareSupplier,
            ]);
            await updateFareIdentifierMappingMutation.mutateAsync({ payload: previousMappings, fareSupplier });
            toast.success(`Instant PNR  changed Successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            queryClient.fetchQuery(['FARE_SUPPLIER', 'FARE_IDENTIFIER_MAPPING', fareSupplier]);
        }
    };

    if (isLoading)
        return (
            <div className="card mt-8 px-6 py-5">
                <div className="font-bold">Fare Identifier Mapping</div>
                <div className="flex items-center justify-center h-28">
                    <Spinner />
                </div>
            </div>
        );

    return (
        <>
            <div className="card mt-8  py-5">
                <div className="flex items-center justify-between px-6">
                    <div className="font-bold">Fare Identifier Mapping</div>
                    <div className="cursor-pointer bg-primary text-white p-2 rounded-full" onClick={createDialog.show}>
                        <PlusIcon height={12} width={12} />
                    </div>
                </div>
                {/* <div className="mt-4">
                    <div className="grid grid-cols-12 text-sm font-medium  px-6 py-2 bg-slate-100">
                        <div className="col-span-3"> Fare Supplier Identifier</div>
                        <div className="col-span-3"> Mapped Identifier</div>
                        <div className="col-span-2"> Info</div>
                        <div className="col-span-4"></div>
                    </div>
                </div> */}
                <table className="card-table text-sm mt-4">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="th-1">Fare Supplier Identifier</th>
                            <th className="th-1">Mapped Identifier</th>
                            <th className="th-1">Preview</th>
                            <th className="th-1">
                                <div className="flex gap-2 items-center">
                                    Instant PNR
                                    <Tooltip
                                        placement="top-start"
                                        content={
                                            <div className="px-4 py-2 text-sm font-semibold text-black/80  ">
                                                Indicates that PNR will not be awaited
                                            </div>
                                        }
                                    >
                                        <InfoCircleIcon height={12} width={12} />
                                    </Tooltip>
                                </div>
                            </th>

                            <th className="th-1"></th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {FareIdentifierMappings.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No entries found. Add to customize fare identifiers. -
                                </td>
                            </tr>
                        )}

                        {!isLoading &&
                            FareIdentifierMappings.map((mapping, index) => (
                                <tr key={index} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div className="font-semibold  text-nowrap">{mapping.supplierIdentifier}</div>
                                    </td>

                                    <td className="td-1">
                                        <div className="font-semibold  text-nowrap">{mapping.mappedIdentifier}</div>
                                    </td>
                                    <td>
                                        <FareIdentifierPreview
                                            backGroundColor={mapping.fareIdentifierLabelColor}
                                            fareIdentifier={mapping.mappedIdentifier}
                                        />
                                        {/* <div className="flex">
                                            <div
                                                style={{ background: mapping.fareIdentifierLabelColor }}
                                                className={` rounded-md px-2 py-1 font-bold text-xs leading-[12px]  `}
                                            >
                                                {mapping.mappedIdentifier}
                                            </div>
                                        </div> */}
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold  text-nowrap">
                                            {mapping.isInstantPnr}
                                            <div className="mb-2">
                                                {/* <label className="text-xs " htmlFor="isEnabled">
                                                    ENABLED
                                                </label> */}

                                                <div className="mt-[2px]">
                                                    <input
                                                        type="checkbox"
                                                        className="form-switch success"
                                                        checked={mapping.isInstantPnr}
                                                        onChange={(e) => handleEnabledChange(index, e.target.checked)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="td-1 flex items-center justify-end gap-4 pr-4">
                                        <div
                                            className="cursor-pointer text-blue-500  "
                                            onClick={() =>
                                                editDialog.show({
                                                    index: index,
                                                    mapping,
                                                })
                                            }
                                        >
                                            <EditPencilIcon />
                                        </div>
                                        <div
                                            onClick={() => deleteDialog.show(index)}
                                            className="cursor-pointer text-red-500  "
                                        >
                                            <DeleteIcon height={20} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {/* {FareIdentifierMappings.length === 0 && (
                    <div className="flex justify-center items-center mt-4 h-32 text-sm font-medium text-black/80">
                        No entries found. Add to customize fare identifiers.
                    </div>
                )} */}
                {/* <div className="">
                    {FareIdentifierMappings.map((mapping, index) => (
                        <div className="grid grid-cols-12 text-sm font-normal hover:bg-slate-50 px-6 py-2 text-black/80">
                            <div className="col-span-3"> {mapping.supplierIdentifier}</div>
                            <div className="col-span-3"> {mapping.mappedIdentifier}</div>
                            <div className="col-span-2"> {mapping.isInstantPnr && 'INSTANT_PNR'}</div>
                            <div className="col-span-4 flex text-center justify-end gap-2">
                                <div
                                    className="cursor-pointer text-blue-500  "
                                    onClick={() =>
                                        editDialog.show({
                                            index: index,
                                            mapping,
                                        })
                                    }
                                >
                                    <EditPencilIcon />
                                </div>
                                <div onClick={() => deleteDialog.show(index)} className="cursor-pointer text-red-500  ">
                                    <DeleteIcon height={20} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
            <AddFareIdentifierMappingModal
                show={createDialog.isOpen}
                onClose={createDialog.hide}
                handleSubmit={handleAddFareIdentifierMapping}
                isLoading={updateFareIdentifierMappingMutation.isLoading}
            />
            {editDialog.isOpen && (
                <EditFareIdentifierMappingModal
                    isLoading={updateFareIdentifierMappingMutation.isLoading}
                    handleSubmit={handleUpdateFareIdentifierMapping}
                    index={editDialog.data.index}
                    initialValues={editDialog.data.mapping}
                    onClose={editDialog.hide}
                    show={editDialog.isOpen}
                />
            )}

            <DeleteModal
                show={deleteDialog.isOpen}
                message={`Are you sure you want to delete mapping for  ${
                    FareIdentifierMappings[deleteDialog?.data]?.supplierIdentifier
                }  `}
                onClose={deleteDialog.hide}
                onConfirm={() => {
                    handleDeleteFareIdentifierMapping(deleteDialog.data);
                }}
                isLoading={updateFareIdentifierMappingMutation.isLoading}
            />
        </>
    );
};

export default FareIdentifierMappingForm;

const FareIdentifierMappingFormSchema = Yup.object().shape({
    supplierIdentifier: Yup.string().trim().required('This field is required'),
    mappedIdentifier: Yup.string().trim().required('This field is required'),
});

interface IAddFareIdentifierMappingModal {
    show: boolean;
    onClose: () => void;
    handleSubmit: (FareIdentifierMap: IFareIdentifierMapping) => Promise<void>;
    isLoading: boolean;
}
const AddFareIdentifierMappingModal: FC<IAddFareIdentifierMappingModal> = (props) => {
    const initialValues = {
        supplierIdentifier: '',
        mappedIdentifier: '',
        isInstantPnr: false,
        fareIdentifierLabelColor: '#ECAE0E',
    };

    const handleSubmit = async (values: IFareIdentifierMapping) => {
        await props.handleSubmit(values);
        props.onClose();
    };

    return (
        <>
            <Modal show={props.show} onClose={props.onClose} className="py-3 w-96 ">
                <div className="px-6 mt-4">
                    <div className="font-bold">Map Fare Identifier </div>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={FareIdentifierMappingFormSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, getFieldProps }: FormikProps<IFareIdentifierMapping>) => (
                                <Form>
                                    <div className="grid grid-cols-12 mt-6">
                                        <div className="col-span-12">
                                            <label className="text-xs mb-2" htmlFor="supplierIdentifier">
                                                Supplier Identifier
                                            </label>

                                            <Field
                                                type="text"
                                                id="supplierIdentifier"
                                                name="supplierIdentifier"
                                                className="form-control"
                                            />

                                            <div className="form-error">
                                                <ErrorMessage name="supplierIdentifier" />
                                            </div>
                                        </div>

                                        <div className="col-span-12">
                                            <label className="text-xs mb-2" htmlFor="mappedIdentifier">
                                                Map To Identifier
                                            </label>

                                            <Field
                                                type="text"
                                                id="mappedIdentifier"
                                                name="mappedIdentifier"
                                                className="form-control"
                                            />

                                            <div className="form-error">
                                                <ErrorMessage name="mappedIdentifier" />
                                            </div>
                                            <div className="col-span-12">
                                                <label className="text-xs mb-2" htmlFor="fareIdentifierLabelColor">
                                                    Fare Identifier Label Color
                                                </label>
                                                <div className="input-group">
                                                    <Field
                                                        type="text"
                                                        id="fareIdentifierLabelColor"
                                                        name="fareIdentifierLabelColor"
                                                        className="form-control pr-8"
                                                    />
                                                    <div
                                                        className="absolute top-0 right-0 w-12 h-full rounded-r-lg border-l border-gray-200"
                                                        style={{ backgroundColor: values.fareIdentifierLabelColor }}
                                                    >
                                                        <input
                                                            type="color"
                                                            className="h-full opacity-0"
                                                            {...getFieldProps('fareIdentifierLabelColor')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-error">
                                                    <ErrorMessage name="fareIdentifierLabelColor" />
                                                </div>
                                            </div>

                                            <div className="col-span-12">
                                                <div className="col-span-2 self-center">
                                                    <div className="flex items-center gap-2 mt-4">
                                                        <Field
                                                            type="checkbox"
                                                            id="isInstantPnr"
                                                            name="isInstantPnr"
                                                            className="form-switch success"
                                                        />

                                                        <label
                                                            className="text-xs flex items-center gap-2"
                                                            htmlFor="isInstantPnr"
                                                        >
                                                            Instant PNR
                                                            <Tooltip
                                                                placement="right"
                                                                content={
                                                                    <div className="px-4 py-2 text-sm font-semibold text-black/80  ">
                                                                        Indicates that PNR will not be awaited
                                                                    </div>
                                                                }
                                                            >
                                                                <InfoCircleIcon height={12} width={12} />
                                                            </Tooltip>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <LoaderButton
                                        type="submit"
                                        className=" mt-6 bg-primary w-full text-white text-sm font-semibold rounded-lg  max-w-full"
                                        isLoading={props.isLoading}
                                    >
                                        Add
                                    </LoaderButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal>
        </>
    );
};

interface IEditFareIdentifierMappingModal {
    show: boolean;
    onClose: () => void;
    initialValues: IFareIdentifierMapping;
    index: number;
    handleSubmit: (FareIdentifierMap: IFareIdentifierMapping, index: number) => Promise<void>;
    isLoading: boolean;
}
const EditFareIdentifierMappingModal: FC<IEditFareIdentifierMappingModal> = (props) => {
    const initialValues = {
        supplierIdentifier: props.initialValues.supplierIdentifier,
        mappedIdentifier: props.initialValues.mappedIdentifier,
        isInstantPnr: props.initialValues.isInstantPnr,
        fareIdentifierLabelColor: props.initialValues.fareIdentifierLabelColor,
    };

    const handleSubmit = async (values: IFareIdentifierMapping) => {
        console.log({ values });
        await props.handleSubmit(values, props.index);
        props.onClose();
    };

    return (
        <>
            <Modal show={props.show} onClose={props.onClose} className="py-3 w-96 ">
                <div className="px-6 mt-4">
                    <div className="font-bold">Edit Fare Identifier Mapping </div>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={FareIdentifierMappingFormSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, getFieldProps }: FormikProps<IFareIdentifierMapping>) => (
                                <Form>
                                    <div className="grid grid-cols-12 mt-6">
                                        <div className="col-span-12">
                                            <label className="text-xs mb-2" htmlFor="supplierIdentifier">
                                                Supplier Identifier
                                            </label>

                                            <Field
                                                type="text"
                                                id="supplierIdentifier"
                                                name="supplierIdentifier"
                                                className="form-control"
                                            />

                                            <div className="form-error">
                                                <ErrorMessage name="supplierIdentifier" />
                                            </div>
                                        </div>

                                        <div className="col-span-12">
                                            <label className="text-xs mb-2" htmlFor="mappedIdentifier">
                                                Map To Identifier
                                            </label>

                                            <Field
                                                type="text"
                                                id="mappedIdentifier"
                                                name="mappedIdentifier"
                                                className="form-control"
                                            />

                                            <div className="form-error">
                                                <ErrorMessage name="mappedIdentifier" />
                                            </div>
                                            <div className="col-span-12">
                                                <label className="text-xs mb-2" htmlFor="fareIdentifierLabelColor">
                                                    Fare Identifier Label Color
                                                </label>
                                                <div className="input-group">
                                                    <Field
                                                        type="text"
                                                        id="fareIdentifierLabelColor"
                                                        name="fareIdentifierLabelColor"
                                                        className="form-control pr-8"
                                                    />
                                                    <div
                                                        className="absolute top-0 right-0 w-12 h-full rounded-r-lg border-l border-gray-200"
                                                        style={{ backgroundColor: values.fareIdentifierLabelColor }}
                                                    >
                                                        <input
                                                            type="color"
                                                            className="h-full opacity-0"
                                                            {...getFieldProps('fareIdentifierLabelColor')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-error">
                                                    <ErrorMessage name="fareIdentifierLabelColor" />
                                                </div>
                                            </div>

                                            <div className="col-span-12">
                                                <div className="col-span-2 self-center">
                                                    <div className="flex items-center gap-2 mt-4">
                                                        <Field
                                                            type="checkbox"
                                                            id="isInstantPnr"
                                                            name="isInstantPnr"
                                                            className="form-switch success"
                                                        />

                                                        <label
                                                            className="text-xs flex items-center gap-2"
                                                            htmlFor="isInstantPnr"
                                                        >
                                                            Instant PNR
                                                            <Tooltip
                                                                placement="right"
                                                                content={
                                                                    <div className="px-4 py-2 text-sm font-semibold text-black/80  ">
                                                                        Indicates that PNR will not be awaited
                                                                    </div>
                                                                }
                                                            >
                                                                <InfoCircleIcon height={12} width={12} />
                                                            </Tooltip>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <LoaderButton
                                        type="submit"
                                        className=" mt-6 bg-primary w-full text-white text-sm font-semibold rounded-lg  max-w-full"
                                        isLoading={props.isLoading}
                                    >
                                        Save
                                    </LoaderButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal>
        </>
    );
};
