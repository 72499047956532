import * as Yup from 'yup';
import { FC } from 'react';
import { toast } from 'react-toastify';
import Spinner from 'app/components/Spinner/Spinner';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FareSupplierEnum, FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getSupplierDefaultCommissionPlan, updateCommissionPlan } from 'app/api/services/fare-commission.service';

const validationSchema = Yup.object({
    managementChargesPerPassenger: Yup.number().required('This field is required'),
    zeroCommissionConditionalManagementChargesPerPassenger: Yup.number().required('This field is required'),
    commissionBreakdown: Yup.object({
        flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
        percentage: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
    }),
});

interface IFareSupplierDefaultCommissionPlan {
    fareSupplier: FareSupplierEnum;
    fareSupplierId: FareSupplierUrlEnum;
}

const FareSupplierDefaultCommissionPlan: FC<IFareSupplierDefaultCommissionPlan> = (props) => {
    const { fareSupplier, fareSupplierId } = props;
    const fareSupplierName = createReadableFareSupplierLabel(fareSupplier);

    const cardLabel = `${fareSupplierName} - Default Commission Plan`;

    const { data, isLoading, isError } = useQuery<any>([fareSupplier, 'DEFAULT COMMISSION PLAN'], () =>
        getSupplierDefaultCommissionPlan(fareSupplierId)
    );

    const updateCommissionPlanMutation = useMutation((payload: { id: string; values: any }) =>
        updateCommissionPlan(payload)
    );

    const handleSubmit = async (values: any) => {
        try {
            await updateCommissionPlanMutation.mutateAsync({
                id: data._id,
                values: {
                    name: data.name,
                    commissionFareSupplier: data.commissionFareSupplier,
                    commissionBreakdown: { netCommission: { ...values.commissionBreakdown } },
                    managementChargesPerPassenger: values.managementChargesPerPassenger,
                    zeroCommissionConditionalManagementChargesPerPassenger:
                        values.zeroCommissionConditionalManagementChargesPerPassenger,
                },
            });
            toast.success('Commission Updated');
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    if (isLoading)
        return (
            <div className="card mt-8 px-6 py-5 h-48">
                <div className="font-bold">{cardLabel}</div>
                <div className="flex justify-center items-center">
                    <Spinner />
                </div>
            </div>
        );

    if (isError)
        return (
            <div className="card mt-8 px-6 py-5 ">
                <div className="font-bold">{cardLabel}</div>
                <div className="flex justify-center items-center h-48">
                    <div className="font-semibold text-lg">Something is missing. Please contact dev team</div>
                </div>
            </div>
        );

    const initialValues = data
        ? {
              managementChargesPerPassenger: data.managementChargesPerPassenger,
              zeroCommissionConditionalManagementChargesPerPassenger:
                  data.zeroCommissionConditionalManagementChargesPerPassenger,
              commissionBreakdown: {
                  flat: data.commissionBreakdown.netCommission.flat,
                  percentage: data.commissionBreakdown.netCommission.percentage,
              },
          }
        : {
              managementChargesPerPassenger: 0,
              zeroCommissionConditionalManagementChargesPerPassenger: 0,
              commissionBreakdown: {
                  flat: 0,
                  percentage: 0,
              },
          };

    return (
        <div>
            <div className="card mt-8 px-6 py-5">
                <div className="font-bold">{cardLabel}</div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ errors }: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className="grid grid-cols-12 gap-6 mt-6">
                                <div className="col-span-2 mt-auto ">
                                    <div className="mt-auto mb-3">
                                        <span className="text-base font-medium">Management Charges</span>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <label className="text-xs mb-2" htmlFor="managementChargesPerPassenger">
                                        Management Charges (Per Seat)
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="managementChargesPerPassenger" />
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <label
                                        className="text-xs mb-2"
                                        htmlFor="zeroCommissionConditionalManagementChargesPerPassenger"
                                    >
                                        Management Charges (Per Seat) When Net Commission Is Zero
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            name={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="zeroCommissionConditionalManagementChargesPerPassenger" />
                                    </div>
                                </div>
                                <div className="col-span-2" />

                                <div className="col-span-2 mt-auto ">
                                    <div className="mt-auto mb-3">
                                        <span className="text-base font-medium">Commission</span>
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <label className="text-xs" htmlFor="">
                                        Flat (Per Seat)
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`commissionBreakdown.flat`}
                                            name={`commissionBreakdown.flat`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name={`commissionBreakdown.flat`} />
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <label className="text-xs" htmlFor="">
                                        Share Net Commission With Sub Agent (%)
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-postpend">%</div>
                                        <Field
                                            type="number"
                                            id={`commissionBreakdown.percentage`}
                                            name={`commissionBreakdown.percentage`}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name={`commissionBreakdown.percentage`} />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-12">
                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                                    isLoading={updateCommissionPlanMutation.isLoading}
                                >
                                    Update
                                </LoaderButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};



export default FareSupplierDefaultCommissionPlan;

// commit just to create new commit on git
