import http from 'app/config/http';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { PaymentEnum } from 'app/enums/payment.enum';
import TableSearch from 'app/components/TableSearch/TableSearch';
import { PaymentStatusEnum } from 'app/enums/payment-status.enum';
import PaymentTable from './components/PaymentTable/PaymentTable';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import { GatewayPaymentModeEnum } from 'app/enums/gateway-payment-mode.enum';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import { ExcelIcon } from 'app/icons';

export interface IGatewayPayment {
    _id: string;
    amount: number;
    method: PaymentEnum;
    details: {
        paymentMode: GatewayPaymentModeEnum;
        transactionId: string;
        transactionDate: string;
        baseAmount: number;
        gatewayCharges: number;
        totalOrderAmount: number;
    };
    agency: string;
    subAgency: {
        _id: string;
        name: string;
        logoUrl: string;
        id: string;
    };
    paidBy: {
        _id: string;
        name: string;
        id: string;
    };
    actionBy: null;
    status: PaymentStatusEnum;
    subAgentRemark: string;
    agentRemarks: [];
    createdAt: string;
    id: 'AUX0689305P';
}

const PaymentListGateway = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);
    let fromDate = searchParams.get('fromDate') || '';
    let toDate = searchParams.get('toDate') || '';
    let page = searchParams.get('page') || 1;
    let status = searchParams.get('status') || '';

    const paymentsQuery = useQuery<IGatewayPayment[]>(
        ['paymentListPaginated', 'GATEWAY', page, fromDate, toDate, status],
        async () => {
            let params = '?get=all&';
            page ? (params += `page=${page}`) : (params += '');
            fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
            toDate ? (params += `&toDate=${toDate}`) : (params += '');
            status ? (params += `&filter=${status}`) : (params += '');

            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/gateway-payments${params}`
            );
            setPageCount(data.pageCount);
            return data.data;
        }
    );

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('query', q);
        } else {
            searchParams.delete('query');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    const handleTransactionStatusChangeFilter = (status: string) => {
        if (status === '') {
            searchParams.delete('status');
        } else {
            searchParams.set('status', status);
        }
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const paymentGatewayTransactionListDownloadMutation = useMutation(async () => {
        let params = '';
        fromDate ? (params += `fromDate=${fromDate}`) : (params += '');
        toDate ? (params += `&toDate=${toDate}`) : (params += '');
        status ? (params += `&filter=${status}`) : (params += '');

        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/gateway-payments/excel?${params}`,
            {
                responseType: 'blob',
            }
        );
        return data;
    });

    const handleDownloadExcel = async () => {
        const res = await paymentGatewayTransactionListDownloadMutation.mutateAsync();
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Payment-Gateway-Report.xlsx');

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
    };

    return (
        <>
            <h5 className="font-bold">Payment Gateway Transactions</h5>

            <div className="card  mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    <TableSearch placeholder="Search for a payment by id, method" onSearch={handleSearch} />
                    <div className="flex items-center gap-3">
                        <label className="text-xs" htmlFor="filter">
                            FILTER
                        </label>
                        <select
                            id="filter"
                            className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium w-48"
                            onChange={(e) => handleTransactionStatusChangeFilter(e.target.value)}
                            value={status}
                            // onChange={(e: any) => handleStatusFilterChange(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="PENDING">Pending</option>
                            <option value="APPROVED">Approved</option>
                            <option value="REJECTED">Rejected</option>
                        </select>
                    </div>

                    <div className="flex gap-4">
                        <TableDateFilter onChange={handleDateFilter} fromDate={fromDate} toDate={toDate} />
                        <button
                            className="cursor-pointer flex items-center justify-center rounded-full"
                            onClick={handleDownloadExcel}
                            disabled={paymentGatewayTransactionListDownloadMutation.isLoading}
                        >
                            <ExcelIcon width="24" height="24" />
                        </button>
                    </div>
                </div>

                <PaymentTable
                    payments={paymentsQuery.data}
                    isLoading={paymentsQuery.isLoading}
                    params={['paymentListPaginated', 'GATEWAY', page, fromDate, toDate, status]}
                />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default PaymentListGateway;
