import * as Yup from 'yup';
import moment from 'moment';
import React, { FC } from 'react';
import http from 'app/config/http';
import Modal from 'app/components/Modal/Modal';
import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';

interface ISalesReport {
    show: boolean;
    onClose: () => void;
}

const SalesReport: FC<ISalesReport> = (props) => {
    const { onClose, show } = props;

    const getMinDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 1); // One year in the past
        return date;
    };

    const ReportFormSchema = Yup.object().shape({
        fromDate: Yup.date()
            .nullable()
            .required('This field is required')
            .max(new Date(), 'Date cannot be in the future')
            .min(getMinDate(), 'Date must be within the past year')
            .test('fromDate-less-than-toDate', 'From date must be equal to or less than To date', function (value) {
                const { toDate } = this.parent; // Access sibling fields
                if (!toDate || !value) return true; // Skip validation if either date is null
                return value <= toDate; // Ensure fromDate is equal to or less than toDate
            }),
        toDate: Yup.date()
            .nullable()
            .required('This field is required')
            .max(new Date(), 'Date cannot be in the future')
            .test('toDate-after-fromDate', 'To date must be equal to or after From date', function (value) {
                const { fromDate } = this.parent; // Access sibling fields
                if (!fromDate || !value) return true; // Skip validation if either date is null
                return value >= fromDate; // Ensure toDate is equal to or after fromDate
            }),
    });

    const salesReportDownloadMutation = useMutation(async (values: { fromDate: string; toDate: string }) => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/reports/sales/excel`, {
            params: { fromDate: values.fromDate, toDate: values.toDate },
            responseType: 'blob',
        });
        return data;
    });

    const handleDownloadExcel = async (values: { fromDate: string; toDate: string }) => {
        const txFromDate = moment(values.fromDate, 'YYYY-MM-DD').format('YYYYMMDD');
        const txToDate = moment(values.toDate, 'YYYY-MM-DD').format('YYYYMMDD');
        const res = await salesReportDownloadMutation.mutateAsync({
            fromDate: txFromDate,
            toDate: txToDate,
        });
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        const name = `Sales-Report-${txFromDate}-${txToDate}.xlsx`;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
        onClose();
    };

    return (
        <Modal
            className="px-6 py-6 w-[480px] "
            show={show}
            onClose={onClose}
            isLoading={salesReportDownloadMutation.isLoading}
        >
            <div className="font-bold text-lg">Sales Report</div>
            <Formik
                initialValues={{
                    fromDate: moment().startOf('month').format('YYYY-MM-DD'), // First date of the current month
                    toDate: moment().format('YYYY-MM-DD'), // Current date
                }}
                enableReinitialize
                validationSchema={ReportFormSchema}
                onSubmit={async (values) => {
                    handleDownloadExcel(values);

                    console.log({ values });
                }}
            >
                {({ values, setFieldValue }: FormikProps<any>) => (
                    <Form>
                        {/* name , gst */}
                        <div className="grid grid-cols-12 gap-3 w-full mt-4">
                            <div className="col-span-full">
                                <label className="text-xs  " htmlFor="fromDate">
                                    From Date
                                </label>
                                <Field type="date" id="fromDate" name="fromDate" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="fromDate" />
                                </div>
                            </div>
                            <div className="col-span-full">
                                <label className="text-xs  " htmlFor="toDate">
                                    To Date
                                </label>
                                <Field type="date" id="toDate" name="toDate" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="toDate" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-full max-w-full mt-4"
                            isLoading={salesReportDownloadMutation.isLoading}
                        >
                            Generate Report
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
            <div className="text-xs black/5 mt-4 flex items-center">* Report generation may take a few minutes</div>
        </Modal>
    );
};

export default SalesReport;
