import { FC } from 'react';
import PassengerDetailRow from './components/PassengerDetailRow/PassengerDetailRow';
import { IBooking, IBookingPassenger } from 'app/types';
import TicketBarCode from '../TicketBarCode/TicketBarCode';

interface IPassengerList {
    booking: IBooking;
}

const PassengerList: FC<IPassengerList> = (props) => {
    const { booking } = props;

    const getAdultBaggage = () => {
        return booking.priceDetail.fareDescription.adult?.baggage;
    };

    const getChildBaggage = () => {
        return booking.priceDetail.fareDescription.child?.baggage;
    };

    const getInfantBaggage = () => {
        return booking.priceDetail.fareDescription.infant?.baggage;
    };

    const barCodeData = {
        airlineCode: booking.departure.flight.code,
        flightNum: booking.departure.flight.number,
        DepartureDate: booking.departure.date,
        fromAirport: booking.departure.airport.code,
        toAirport: booking.arrival.airport.code,
    };

    const getPnrOfFirstSegment = (passenger: IBookingPassenger): string | undefined => {
        const selectedPnr = passenger.pnrs.find((pnr: any) =>
            (pnr.sector as string).startsWith(booking.departure.airport.code)
        );
        if (!selectedPnr) return null;
        return selectedPnr.number;
    };

    return (
        <div className="mt-12 mb-12">
            <div className="px-10">
                <div className=" font-semibold text-primary">Passenger Details</div>
            </div>

            <table className="w-full mt-6">
                <thead>
                    <tr className="bg-primary/20">
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                            Name
                        </th>
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                            Airline PNR
                        </th>
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                            Ticket Number
                        </th>
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left" />
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                            Cabin
                        </th>
                        <th className="py-2 px-3 align-middle first:pl-10 last:pr-10 text-xs font-normal text-left">
                            Baggage
                        </th>
                    </tr>
                </thead>
                <tbody className="text-sm">
                    {booking.passengers.adults.map((passenger) => (
                        <PassengerDetailRow
                            passenger={passenger}
                            passengerType="Adult"
                            cabin={getAdultBaggage()?.cabin}
                            checkIn={getAdultBaggage()?.checkIn}
                            key={`${passenger.title}-${passenger.firstName}-${passenger.lastName}`}
                            BarCode={
                                <TicketBarCode
                                    firstName={passenger.firstName}
                                    lastName={passenger.lastName}
                                    pnr={getPnrOfFirstSegment(passenger)}
                                    {...barCodeData}
                                />
                            }
                        />
                    ))}
                    {booking.passengers.children.map((passenger: any) => (
                        <PassengerDetailRow
                            passenger={passenger}
                            passengerType="Child"
                            cabin={getChildBaggage()?.cabin}
                            checkIn={getChildBaggage()?.checkIn}
                            key={`${passenger.title}-${passenger.firstName}-${passenger.lastName}`}
                            BarCode={
                                <TicketBarCode
                                    firstName={passenger.firstName}
                                    lastName={passenger.lastName}
                                    pnr={getPnrOfFirstSegment(passenger)}
                                    {...barCodeData}
                                />
                            }
                        />
                    ))}
                    {booking.passengers.infants.map((passenger: any) => (
                        <PassengerDetailRow
                            passenger={passenger}
                            passengerType="Infant"
                            cabin={getInfantBaggage()?.cabin}
                            checkIn={getInfantBaggage()?.checkIn}
                            key={`${passenger.title}-${passenger.firstName}-${passenger.lastName}`}
                            BarCode={
                                <TicketBarCode
                                    firstName={passenger.firstName}
                                    lastName={passenger.lastName}
                                    pnr={getPnrOfFirstSegment(passenger)}
                                    {...barCodeData}
                                />
                            }
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PassengerList;
