import BackLink from 'app/components/BackLink/BackLink';
import TravelBoutiqueCredentialsForm from '../shared/components/TravelBoutiqueCredentialsForm/TravelBoutiqueCredentialsForm';
import PageLoader from 'app/components/PageLoader/PageLoader';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { Status } from 'app/enums/status.enum';
import { ITravelBoutiqueDetail } from '../shared/types/travel-boutique';
import { IMarkUpHistory } from '../shared/types/mark-up-history';

import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';

const FareSupplierTravelBoutique = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<ITravelBoutiqueDetail>({
        fareIdentifier: FareSupplierEnum.TRAVEL_BOUTIQUE,
    });

    if (isLoading) return <PageLoader />;

    const data: ITravelBoutiqueDetail = isError
        ? {
              clientId: '',
              password: '',
              username: '',
              markup: 0,
              fareRules: [] as string[],
              markupHistory: [] as IMarkUpHistory[],
              status: Status.DISABLED,
              authUrl: '',
              bookingUrl: '',
              searchUrl: '',
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Travel Boutique Details</div>
                    <FareSupplierActivationToggle
                        checked={data.status}
                        handleToggleStatus={handleToggleStatus}
                        fareSupplierLabel={fareSupplierLabel}
                        isLoading={isUpdatingFareSupplierStatus}
                    />
                </div>

                <TravelBoutiqueCredentialsForm
                    authUrl={data.authUrl}
                    bookingUrl={data.bookingUrl}
                    searchUrl={data.searchUrl}
                    clientId={data.clientId}
                    password={data.password}
                    username={data.username}
                />
            </div>

            <FareSupplierDefaultCommissionPlan fareSupplierId={fareSupplierUrlKey} fareSupplier={fareSupplier} />

            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />

            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />

            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'tbo'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}
        </>
    );
};

export default FareSupplierTravelBoutique;
