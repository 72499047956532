import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IManualBooking, IPassengerWithTicketNumber } from 'app/types/manual-booking';
import { createManualBooking } from 'app/api/services/booking.service';
import { IManualBookingForm, ISegmentInitial } from '../types/IManualBooking';
import Modal from 'app/components/Modal/Modal';
// import useDialog from 'app/hooks/use-dialog';
import { RefundableEnum } from 'app/enums/refundable.enum';
import { CabinClassEnum } from 'app/enums/cabin-class.enum';
import { ErrorMessage, Field, FieldArray } from 'formik';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import { IAirport } from 'app/types';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { getCabinClassLabel, getRefundTypeLabel } from 'app/utils/ticket-utils';
import moment from 'moment';
import { SYSTEM_DATE_FORMAT, SYSTEM_TIME_FORMAT } from 'app/utils/date-utils';
import http from 'app/config/http';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import ManualBookingReviewModal from './ManualBookingReviewModal';

interface IFlightForm {
    formKey: string;
}

const FlightForm: FC<IFlightForm> = (props) => {
    const { formKey } = props;
    const [formValues, setFormValues] = useState<IManualBookingForm>();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [showReviewModel, setShowReviewModel] = useState(false);
    const [formData, setFromData] = useState<Partial<IManualBookingForm>>({});

    // const confirmationDialog = useDialog();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const manualBookingMutation = useMutation((payload: IManualBooking) => createManualBooking(payload));

    const handleManualBookingCreation = async (value: IManualBooking) => {
        try {
            await manualBookingMutation.mutateAsync(value);
            localStorage.removeItem('manualBookingFormState');
            toast.success('Manual booking create Successfully');
            navigate('/bookings?tab=4&page=1');
        } catch (e: any) {
            // toast.error('Something went wrong');
            toast.error(e?.data?.message || 'Something went wrong');
            console.log(e);
        }
    };

    const handleNextClick = (values: Partial<IManualBookingForm>) => {
        setFormValues((prev: any) => ({ ...prev, ...values }));
        setStep((prev) => prev + 1);
    };

    const handlePrevClick = () => {
        setStep((prev) => prev - 1);
    };
    const handleShowReview = (values: Partial<IManualBookingForm>) => {
        setShowReviewModel(!showReviewModel);
        setFromData({ ...values, ticket: formValues.ticket });
    };

    const handleFinalSubmission = () => {
        const values = formData;
        const finalValue: IManualBooking = {
            booking: {
                ...values.booking,
                bookingId: formValues.ticket.bookingId,
                pnr: formValues.ticket.pnr,
                subAgency: formValues.ticket.subAgency._id,
            },
            ticket: {
                baggage: formValues.ticket.baggage,
                segments: formValues.ticket.segments,
                meta: formValues.ticket.meta,
                // mealIndicator: true,
                classOfBooking: formValues.ticket.classOfBooking,
                fareIdentifier: formValues.ticket.fareIdentifier,
                refundableType: formValues.ticket.refundableType,
                cabinClass: formValues.ticket.cabinClass,
            },
        };

        if (finalValue.ticket.meta.fareSupplier !== 'OTHER') {
            delete finalValue.ticket.meta.fareSupplierName;
        } else {
            delete finalValue.ticket.meta.fareSupplier;
        }

        console.log({ finalValue, values, formValues });

        if (true) handleManualBookingCreation(finalValue);
    };

    const checkIsInternationalFlight = (segment: ISegmentInitial[]) => {
        return (
            segment[0].departure.airport.countryCode !== 'IN' ||
            segment[segment.length - 1].arrival.airport.countryCode !== 'IN'
        );
    };

    return (
        <>
            <div className={step === 1 ? 'block' : 'hidden'}>
                {step === 1 && <Step1Form onSubmit={handleNextClick} formKey={formKey} />}
            </div>

            <div className={step === 2 ? 'block' : 'hidden'}>
                {step === 2 && (
                    <Step2Form
                        onBack={handlePrevClick}
                        // onSubmit={handleFinalSubmission}
                        onSubmit={handleShowReview}
                        passengers={formValues.ticket.passengers}
                        subAgency={formValues.ticket.subAgency}
                        isMutating={manualBookingMutation.isLoading}
                        tripDate={formValues.ticket.segments[0].departure.date}
                        isInternational={checkIsInternationalFlight(formValues.ticket.segments)}
                        segments={formValues.ticket.segments}
                    />
                )}
            </div>
            {showReviewModel && (
                <ManualBookingReviewModal
                    // manualBooking={fxValues}
                    manualBooking={formData}
                    show={showReviewModel}
                    onClose={() => {
                        setShowReviewModel(false);
                    }}
                    isMutating={manualBookingMutation.isLoading}
                    // formData={formData}
                    handleFinalSubmission={handleFinalSubmission}
                />
            )}
        </>
    );
};

export default FlightForm;


