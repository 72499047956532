import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';
import React, { FC } from 'react';
import contryList from 'country-list';
import moment from 'moment';
interface PassengerDetailProps {
    index: number;
    type: string;
    row: any;
    isInternational: boolean;
}

const PassengerDetail: FC<PassengerDetailProps> = (props) => {
    const { index, row, type, isInternational } = props;
    return (
        <>
            <div key={index} className="grid grid-cols-6 gap-8 mb-3">
                <div className="col-span-1">
                    <div className="font-medium"> {`${type} ${index + 1}`} </div>
                </div>
                <div className="col-span-4">
                    <div className="font-medium">
                        {row.title} {row.firstName} {row.lastName}
                    </div>
                    <div className="text-xs">
                        {getGenderFromTitle(row.title)}, {type}
                        {(type === 'Infant' || isInternational) && (
                            <span className="text-xs font-normal"> | DOB - {formatDOB(row.dob, 'YYYY-MM-DD')}</span>
                        )}
                    </div>
                    {/* {(type === 'Infant' || isInternational) && (
                        <div className="text-xs font-normal">(DOB - {formatDOB(row.dob, 'YYYY-MM-DD')})</div>
                    )} */}
                </div>
                <div className="col-span-1 dummy" />

                {isInternational && (
                    <>
                        <div className="col-span-1 dummy" />
                        <div className="col-span-1 flex flex-col justify-between">
                            <div className="text-xs font-normal text-gray-500">Nationality</div>
                            <div className="text-sm font-normal"> {contryList.getName(row.passport.nationality)} </div>
                        </div>
                        <div className="col-span-1 flex flex-col justify-between ">
                            <div className="text-xs font-normal text-gray-500">Passport #</div>
                            <div className="text-sm font-normal"> {row.passport.number} </div>
                        </div>
                        <div className="col-span-1 flex flex-col justify-between ">
                            <div className="text-xs font-normal text-gray-500">Issue Date</div>
                            <div className="text-sm font-normal">
                                {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
                            </div>
                        </div>
                        <div className="col-span-1 flex flex-col justify-between ">
                            <div className="text-xs font-normal text-gray-500">Expiry Date</div>
                            <div className="text-sm font-normal">
                                {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
                            </div>
                        </div>
                        {/* <div className="col-span-1 flex flex-col justify-between ">
                            <div className="text-xs font-normal text-gray-500">Expiry Date:</div>
                            {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
                        </div> */}
                        <div className="col-span-1" />{' '}
                    </>
                )}

                <div className="col-span-1" />
                <div className="col-span-2">
                    <div className="space-y-1">
                        <div className="col-span-1">
                            <div className="text-xs text-gray-500">Airline PNR</div>
                        </div>
                        {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                                <div className="font-medium">
                                    {pnr.number} <span className="text-xs">({pnr.sector})</span>{' '}
                                </div>
                            </div>
                        ))}
                        {row.pnrs.length === 0 && <div className="font-medium">-</div>}
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="space-y-1">
                        <div className="col-span-1">
                            <div className="text-xs text-gray-500">Ticket Number</div>
                        </div>
                        {row.ticketNumbers &&
                            row.ticketNumbers.map((ticket: any, i: number) => (
                                <div key={i}>
                                    <div className="font-medium">
                                        {ticket.number} <span className="text-xs">({ticket.sector})</span>{' '}
                                    </div>
                                </div>
                            ))}

                        {row.ticketNumbers && row.ticketNumbers.length === 0 && <div className="font-medium">-</div>}
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="space-y-1">
                        <div className="col-span-1">
                            <div className="text-xs text-gray-500">Seat(s)</div>
                        </div>
                        {row.seatNumbers &&
                            row.seatNumbers.map((seatNumber: any, i: number) => (
                                <div key={i}>
                                    <div className="font-medium">
                                        {seatNumber.number} <span className="text-xs">({seatNumber.sector})</span>{' '}
                                    </div>
                                </div>
                            ))}
                        {row.seatNumbers && row.seatNumbers.length === 0 && <div className="font-medium">-</div>}
                    </div>
                </div>
            </div>

            {/* {isInternational && (
                <div key={index} className="grid grid-cols-5 gap-8 mb-2 min-w-[650px] text-xs mt-1">
                    <div className="col-span-1"></div>
                    <div className="col-span-1 flex flex-col justify-between">
                        <div className="text-xs font-normal text-gray-500">Nationality :</div>
                        <div className="text-sm font-normal"> {contryList.getName(row.passport.nationality)} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Passport :</div>
                        <div className="text-sm font-normal"> {row.passport.number} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Issue :</div>
                        <div className="text-sm font-normal">
                            {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Expiry :</div>
                        {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
                    </div>
                </div>
            )} */}
            <div className="grid grid-cols-6">
                <div className="col-span-1 dummy"></div>
                <div className="col-span-5 border-b last:border-none my-5"></div>
            </div>
        </>
    );
};

export default PassengerDetail;
