import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { assertUnreachable } from './common-utils';

export const createReadableFareSupplierLabel = (fareSupplier: FareSupplierEnum) => {
    switch (fareSupplier) {
        case FareSupplierEnum.AGENCY_SERIES:
            return 'Agency Series';
        case FareSupplierEnum.AIR_IQ:
            return 'Air IQ';
        case FareSupplierEnum.MY_SERIES:
            return 'Supplier Series';
        case FareSupplierEnum.SPICE_JET:
            return 'Spice Jet';
        case FareSupplierEnum.TRAVEL_BOUTIQUE:
            return 'Travel Boutique';
        case FareSupplierEnum.TRIP_JACK:
            return 'Trip Jack';
        case FareSupplierEnum.UNIVERSAL_API:
            return 'Universal Api';
        case FareSupplierEnum.FARE_BOUTIQUE:
            return 'Fare Boutique';
        case FareSupplierEnum.KAFILA:
            return 'Kafila';
        case FareSupplierEnum.MTC:
            return 'MTC';
        default:
            assertUnreachable(fareSupplier);
            return '';
    }
};