import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

const modalAnimation = {
    variants: {
        hidden: {
            opacity: 0,
            y: '-100vh',
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.1, type: 'spring', damping: 25, stiffness: 250 },
        },
        exit: {
            y: '-100vh',
            opacity: 0,
        },
    },
    initial: 'hidden',
    animate: 'visible',
    exit: 'exit',
};

interface ModalProps {
    show: boolean;
    children: ReactNode;
    onClose: () => void;
    onBackdropClick?: () => void;
    className?: string;
    isLoading?: boolean;
}

const Modal: FC<ModalProps> = (props) => {
    const { show, onBackdropClick, onClose, className, children, isLoading } = props;

    return createPortal(
        <AnimatePresence initial={false} mode="wait">
            {show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[38] bg-black/40"
                    onClick={isLoading ? () => {} : onBackdropClick || onClose}
                >
                    <div className="fixed left-0 top-0 w-full h-full overflow-x-hidden overflow-y-auto z-[39]">
                        <div className="flex items-center justify-center w-auto m-4 min-h-[calc(100%-2rem)]">
                            <motion.div
                                {...modalAnimation}
                                className={[
                                    'relative card rounded-xl z-[40] max-w-full overflow-hidden min-w-[160px]',
                                    className,
                                ].join(' ')}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <button
                                    type="button"
                                    className="disabled:cursor-not-allowed absolute top-4 right-3 text-gray-400 text-3xl p-1 cursor-pointer z-[41] font-light leading-[0]"
                                    onClick={onClose}
                                    disabled={isLoading}
                                >
                                    &times;
                                </button>

                                <div>{children}</div>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById('modal-root')
    );
};

Modal.defaultProps = {
    isLoading: false,
};

export default Modal;
